import React from 'react';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';

class ConfirmationDialogProps {
    constructor(setShowConfirmation, showConfirmation, confirmationFunction, header, body, confirmationButtonText, additionalButtonText, additionalButtonFunction) {
        this.setShowConfirmation = setShowConfirmation;
        this.showConfirmation = showConfirmation;
        this.confirmationFunction = confirmationFunction;
        this.header = header;
        this.body = body;
        this.confirmationButtonText = confirmationButtonText;
        this.additionalButtonText = additionalButtonText;
        this.additionalButtonFunction = additionalButtonFunction;
    }
}

function ConfirmationDialog(props) {
    const handleClose = () => props.setShowConfirmation(false);

    const handleClick = () => {
        props.confirmationFunction();
        handleClose();
    };

    const handleAdditionalButtonClick = () => {
        props.additionalButtonFunction();
        handleClose();
    };

    return (
        <>
            <Modal show={props.showConfirmation} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{props.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.body.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            {index < props.body.split('\n').length - 1 && <br />}
                        </React.Fragment>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outlined" color="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    {props.additionalButtonFunction && <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAdditionalButtonClick}
                        style={{ marginLeft: 8 }}>
                        {props.additionalButtonText}
                    </Button>}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        style={{ marginLeft: 8 }}>
                        {props.confirmationButtonText}
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default ConfirmationDialog;
export { ConfirmationDialogProps };