import { API } from 'aws-amplify';

import LensData from '../models/lens_data.js'

async function deleteLensDataAPI(uid, hasPdf) {
    var successful = false;

    const payload = {
        queryStringParameters: {
            uid: uid,
            hasPdf: hasPdf
        }
    };

    try {
        var response = await API.del('EVOCalculatorAPI', '/lensData', payload);
        console.log("Delete from DB")
        console.log(response);

        successful = true;

    } catch (error) {
        console.log(error.response);
    }

    //return new LensData({ gotFromDatabaseThisSession: true, uid: uid, finishedSignup: true });
    return successful;
}

async function createLensDataAPI(lensData, base64EncodedFile) {
    var result = { "successful": false, "uid": null };

    const payload = {
        body: { lensData: lensData.toJSONString() }
    };

    if (base64EncodedFile) {
        payload["body"]["pdf"] = base64EncodedFile;
    }

    try {
        var response = await API.post('EVOCalculatorAPI', '/lensData', payload)
        console.log("Created lensData")
        console.log(response);
        result.successful = true;
        result.uid = response.uid;
    } catch (error) {
        console.log(error.response);
    }

    return result;
}

// Takes a dictionary of updates.
async function updateLensDataAPI(updates, uid) {
    var successful = false;

    // Check if dictionary
    if (updates.constructor === Object) {
        updates["uid"] = uid;
        updates = JSON.stringify(updates);

        const payload = {
            body: { updates: updates }
        };

        try {
            var response = await API.put('EVOCalculatorAPI', '/lensData', payload)
            console.log("Updated lensData")
            console.log(response);
            successful = true;
        } catch (error) {
            console.log(error.response.data.message);
        }
    }
    else {
        throw new Error('Updates must be a dictionary.');
    }

    return successful;
}

async function parseLensDataPdfAPI(base64EncodedFile) {

    const payload = {
        body: {
            pdf: base64EncodedFile
        }
    };

    try {
        var response = await API.post('EVOCalculatorAPI', '/lensData/parsePdf', payload);
        console.log("Parsed PDF")
        console.log(response);

        if (Object.keys(response.lensData).length !== 0) {
            return response.lensData;
        }
    } catch (error) {
        console.log(error.response);
    }

    return null;
}

// Takes a dictionary of updates.
async function getLensDataPaginateAPI(teamUid, patientId, patientDob, vault, lastEvaluatedKey) {
    let payload = {
        "teamUid": teamUid,
        "pageSize": 10,
    };

    if (lastEvaluatedKey) {
        payload["lastEvaluatedKey"] = JSON.stringify(lastEvaluatedKey);
    }
    if (vault === true) {
        payload["vault"] = 'null';
    }
    if (patientId !== null && patientId !== "") {
        payload["patientId"] = patientId;
    }
    if (patientDob !== null && patientDob !== "") {
        payload["patientDob"] = patientDob;
    }

    payload = { "body": payload };

    try {

        const response = await API.post('EVOCalculatorAPI', '/lensData/paginate', payload);

        //console.log(response);

        let lensData = [];

        for (var index in response.items) {
            lensData.push(LensData.fromJSON(response.items[index]));
        }

        return [lensData, response.lastEvaluatedKey];
    } catch (error) {
        console.log(error);
    }

    return [{}, null]
}

export { updateLensDataAPI, createLensDataAPI, parseLensDataPdfAPI, getLensDataPaginateAPI, deleteLensDataAPI }