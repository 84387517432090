import React from 'react';
import { Spinner } from 'react-bootstrap';

function LoadingSpinner({ color = '#353535', inDashboard = false }) {
    return (
        <div className={inDashboard ? "overlay" : ""} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Spinner animation="border" style={{ borderColor: color, borderTopColor: 'transparent' }} role="status">
            </Spinner>
        </div>
    );
}

export default LoadingSpinner;
