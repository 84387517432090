import Header from "./components/Header.js"
import Sidebar from "./components/Sidebar.js"
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import React, { useState, useContext, useEffect } from 'react';
import { WifiOff } from "@mui/icons-material";

import TeamContext from '../contexts/TeamContext.js';
import DataViewerContext from '../contexts/DataViewerContext.js';

function DashboardLayout() {
    const { team, setTeam } = useContext(TeamContext);
    const { dataViewerState, setDataViewerState } = useContext(DataViewerContext);
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        function handleOnline() {
            setIsOnline(true);
        }

        function handleOffline() {
            setIsOnline(false);
        }

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    useEffect(() => {
        // When the team is changed, reset the data viewer context
        setDataViewerState({ data: [], filters: { patientId: "", patientDob: null, vault: false } });
    }, [team]);

    return (
        isOnline ?
            <div
                style={{
                    background: "#E5E5E5",
                    minHeight: "100vh",
                    display: "flex",
                    flexDirection: "row",
                }}>
                <Sidebar />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}>
                    <Header />
                    <Container style={{ padding: "10px 40px", height: "93vh", overflowY: "auto" }}>
                        <Outlet />
                    </Container>
                </div>
            </div>
            :
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: "column", justifyContent: 'center', height: '100vh' }}>
                <h3>EVO Calculator requires an internet connection </h3>
                <WifiOff color="error" fontSize="large" />
            </div>
    );
}




export default DashboardLayout;










// return (
//     <div
//         style={{
//             background: "linear-gradient(to top, #5068E1, #7A5DC7)",
//             minHeight: "100vh",
//             display: "flex",
//             flexDirection: "row",
//         }}
//     >
//         <div
//             style={{
//                 background: "rgba(0, 0, 0, 0.7)",
//                 height: "100vh",
//                 width: "300px",
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "flex-start",
//                 justifyContent: "flex-start",
//                 boxShadow: "5px 0px 5px rgba(0,0,0,0.2)",
//                 padding: "1rem",
//             }}
//         >
//             <h4 style={{ color: "#DFDFDF", marginBottom: "1rem" }}>EVO Calculator</h4>
//             <hr style={{ border: "1px solid #DFDFDF", width: "100%", margin: "0 0 1rem 0" }} />
//             {navLinks.map((link) => (
//                 <NavLink
//                     key={link.to}
//                     to={link.to}
//                     style={{
//                         color: "#DFDFDF",
//                         textDecoration: "none",
//                         marginBottom: "0.5rem",
//                         fontWeight: "normal",
//                         backgroundColor:
//                             location.pathname === link.to
//                                 ? "rgba(140, 140, 140, 0.5)"
//                                 : hovered === link.label
//                                     ? "rgba(140, 140, 140, 0.5)"
//                                     : "transparent",
//                         padding: "0.7rem",
//                         borderRadius: "5px",
//                         width: "100%",
//                         display: "flex",
//                         alignItems: "center",
//                     }}
//                     onMouseEnter={() => handleMouseEnter(link.label)}
//                     onMouseLeave={handleMouseLeave}
//                 >
//                     <FontAwesomeIcon icon={link.icon} style={{ marginRight: "1rem" }} />
//                     {link.label}
//                 </NavLink>
//             ))}
//         </div>
//         <div
//             style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 width: "100%",
//             }}
//         >
//             <Header />
//             <Outlet />
//             <footer style={{ paddingTop: "1rem" }}>
//                 <Container>
//                     <p style={{ textAlign: "center" }}>
//                         © {new Date().getFullYear()} <a>Optica, Inc.</a>
//                     </p>
//                 </Container>
//             </footer>
//         </div>
//     </div>
// );













// function DashboardLayout() {
//     return (
//       <div style={{
//           background: "linear-gradient(to top, #5068E1, #7A5DC7)",
//           minHeight: "100vh",
//           display: "flex",
//           flexDirection: "row",
//       }}>
//           <div style={{
//               background: "#f2f2f2",
//               height: "100vh",
//               width: "200px",
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               justifyContent: "center",
//               boxShadow: "5px 0px 5px rgba(0,0,0,0.2)",
//           }}>
//               <h4 style={{ color: "#333", textAlign: "center", marginBottom: "2rem" }}>EVO Calculator</h4>
//               <NavLink to="/dashboard/data-uploader" style={{ color: "#555", textDecoration: "none", marginBottom: "1rem" }}>Data Uploader</NavLink>
//               <NavLink to="/dashboard/teams" style={{ color: "#555", textDecoration: "none", marginBottom: "1rem" }}>Teams</NavLink>
//           </div>
//           <div style={{
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "space-between",
//               width: "100%",
//           }}>
//               <Header />
//               <Outlet />
//               <footer style={{ paddingTop: "1rem" }}>
//                   <Container>
//                       <p style={{ textAlign: "center" }}>
//                           © {new Date().getFullYear()}{" "}
//                           <a>Optica, Inc.</a>
//                       </p>
//                   </Container>
//               </footer>
//           </div>
//       </div>
//     );
//   }








// // components/Layout.js
// import React from 'react';
// import { Outlet, useNavigate } from 'react-router-dom';
// import { useAuthenticator } from '@aws-amplify/ui-react';
// import Header from "../dashboard/components/Header.js"
// import { Container } from 'react-bootstrap';

// function DashboardLayout(isLoggedIn) {
//     return (
//         <div style={{
//             background: "linear-gradient(to top, #5068E1, #7A5DC7)",
//             minHeight: "100vh",
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "space-between",
//         }}>
//             {isLoggedIn && <Header />}
//             <Outlet />
//             <footer style={{ paddingTop: "1rem" }}>
//                 <Container>
//                     <p style={{ textAlign: "center" }}>
//                         © {new Date().getFullYear()}{" "}
//                         <a>Optica, Inc.</a>
//                     </p>
//                 </Container>
//             </footer>
//         </div>
//     );
// }

// export default DashboardLayout;