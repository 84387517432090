import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

class CustomSnackbarProps {
    constructor(show, handleCloseAlert, message, severity) {
        this.show = show;
        this.handleCloseAlert = handleCloseAlert;
        this.message = message;
        this.severity = severity;
    }
}

function CustomSnackbar(props) {
    return (
        <>
            <Snackbar open={props.show} autoHideDuration={4000} onClose={props.handleCloseAlert}>
                <MuiAlert elevation={6} variant="filled" severity={props.severity} sx={{ width: '100%' }}>
                    {props.message}
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default CustomSnackbar;
export { CustomSnackbarProps };
