import React, { useState, useContext, useEffect } from 'react';
import { Container, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';
import CustomSnackbar, { CustomSnackbarProps } from '../../components/Snackbar';

import { updateTeamAPI, createTeamAPI } from '../../api/TeamAPI.js'
import LensData from '../../models/lens_data.js';
import Team from '../../models/team.js';
import User from '../../models/user.js';
import TeamContext from '../contexts/TeamContext.js';
import UserContext from '../contexts/UserContext.js';
import { TextField, Box, Autocomplete, Typography, Tooltip, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function CreateTeam(props) {
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);
    const { team, setTeam } = useContext(TeamContext);
    const isEditTeam = props.isEditTeam;
    const [localTeam, setLocalTeam] = useState(isEditTeam ? team : new Team({ members: { [user.uid]: "1" }, memberEmails: { [user.uid]: user.email } }));
    const lensData = new LensData();
    const [isLoading, setIsLoading] = useState(false);
    const [newTeamName, setNewTeamName] = useState(team?.teamName);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [showAlertValues, setShowAlertValues] = useState({ show: false });
    var measurementIds = Object.keys(lensData.measurementIdToDeviceIdAttribute).filter(function (key) {
        return lensData.measurementIdToDeviceIdAttribute[key].toLowerCase().includes('id');
    });
    // K1 and K2 fall under the same category, so remove them.
    measurementIds = measurementIds.filter(function (item) {
        return item !== "6" && item !== "7" && item !== "8";
    });

    const noInputColor = '#C1C1C1'
    const hasInputColor = '#292929'

    var boxStyles = {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.7)'
        },
        '& .MuiInputLabel-shrink': {
            color: 'rgba(0, 0, 0, 0.8)',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.5)',
        },
        '& .MuiInputLabel-asterisk': {
            color: '#E28B00',
            fontSize: '1rem',
            fontWeight: 'bold',
        }
    }

    useEffect(() => {
        if (isEditTeam && team) {
            setNewTeamName(team.teamName);
            setLocalTeam(team);
        }

    }, [team]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);

        createTeamAPI(localTeam).then(([successful, uid]) => {
            if (successful) {
                let newUser = new User(user.toJSON())
                localTeam.uid = uid;

                // This is set in the API call, so set locally.
                newUser.teams[localTeam.uid] = '1';
                newUser.teamObjects.push(localTeam);

                setUser(newUser);
                setTeam(localTeam);

                setIsLoading(false);
                setShowAlertValues({ show: true, message: "Created Team", severity: "success" });

                navigate('/dashboard');
            } else {
                setIsLoading(false);
                setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });
            }
        }).catch((err) => {
            console.log(err);
            setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });
            setIsLoading(false);
        });

    };

    const updateTeam = (teamUpdates, newTeam) => {
        updateTeamAPI(teamUpdates, team.uid).then((successful) => {
            if (successful) {
                setShowAlertValues({ show: true, message: "Saved", severity: "success" });

                setTeam(newTeam);

                const newUser = User.fromJSON({ json: user.toJSON() });
                newUser.updateTeam(newTeam);
                setUser(newUser);
            }
            else {
                setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });
            }

            setIsLoading(false);
        }).catch((err) => {
            console.log(err);
            setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });
            setIsLoading(false);
        });
    }

    const handleSave = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const newTeam = Team.fromJSON(localTeam.toJSON());
        newTeam.teamName = newTeamName;

        const updates = { teamName: newTeamName };

        updateTeam(updates, newTeam)
    }

    const toggleDialog = (id) => {
        setDialogOpen(true);
    }

    const handleCloseAlert = (event, reason) => {
        reason === 'clickaway' || setShowAlertValues({ show: false });
    };

    const handleDropdownChange = (id, event, value) => {
        const newTeam = Team.fromJSON(localTeam.toJSON());

        var attribute = lensData.measurementIdToDeviceIdAttribute[id];
        const deviceId = value != null ? lensData.deviceNameToId(value) : null;
        newTeam[attribute] = deviceId;

        if (isEditTeam) {
            setIsLoading(true)

            const teamUpdates = { [attribute]: deviceId };

            updateTeam(teamUpdates, newTeam)
        }
        else {
            setLocalTeam(newTeam);
        }
    };

    const dropDownValue = (id) => {
        var deviceName = "";
        var attribute = lensData.measurementIdToDeviceIdAttribute[id];
        var deviceId = localTeam[attribute]

        if (deviceId != null) {
            deviceName = lensData.deviceIdToName(deviceId);
        }

        return deviceName;
    }

    const getDropdownLabelAndTitle = (label, title, id) => {
        if (label === "Device") {
            label = lensData.measurementIdToDeviceLabel[id] || "";
            var name = lensData.measurementNameFromId(id);

            if (name === "K1") {
                name = "Keratometry";
                title = "Keratometry";
            }

            if (label === "") {
                label = `${name} Device`;
            }


            title = `${title} Device`
        }

        return [label, title];
    }

    const dropdownInput = (id, { label = "Device", minWidth = 280, maxWidth = null, ml = null } = {}) => {
        let deviceNames = lensData.deviceNamesForId(id);
        let measurement = lensData.measurementFromId(id);
        var title = lensData.measurementIdToTip[id];
        var value = dropDownValue(id);
        var hasValue = value !== "" && value !== null;
        var deviceIdAttribute = lensData.measurementIdToDeviceIdAttribute[id];
        var isDevice = deviceIdAttribute.toLowerCase().includes("id");


        var newBoxStyles = {
            ...boxStyles, minWidth: minWidth,
            ml: ml,
            maxWidth: maxWidth,
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: hasValue ? hasInputColor : noInputColor,
                borderWidth: '1.5px'
            }
        };

        [label, title] = getDropdownLabelAndTitle(label, title, id);

        if (isDevice) {
            deviceNames.unshift("Request New Device");
        }

        return (
            <div>
                {deviceNames.length - 1 > 0 &&

                    <Autocomplete
                        disablePortal
                        clearOnEscape={true}
                        options={deviceNames}
                        value={value || null}
                        onChange={(event, value) => handleDropdownChange(id, event, value)}
                        renderOption={(props, option) => {
                            if (option === deviceNames[0] && isDevice) {
                                return (
                                    <Box key={option} className="d-flex justify-content-center align-items-center">
                                        <Button
                                            {...props}
                                            variant="outline-secondary" size="sm" style={{ justifyContent: "center", width: "95%", marginBottom: "10px", backgroundColor: '#6C6C6C', color: '#FFFFFF' }}
                                            onClick={() => { window.location.href = `mailto:jpetrisko@berkeley.edu?subject=${title} Request`; }}>
                                            {option}
                                        </Button>
                                    </Box>
                                )
                            } else {
                                return (<li key={option} {...props}>{option}</li>);
                            }
                        }}
                        renderInput={(params) =>
                            <Tooltip title={title} disableInteractive>
                                <TextField
                                    {...params}
                                    size="small"
                                    label={label}
                                    sx={newBoxStyles}
                                />
                            </Tooltip>
                        }
                    />
                }
            </div >
        );
    }

    const handleNameChange = (value) => {
        if (isEditTeam) {
            setNewTeamName(value);
        }
        else {
            const newTeam = Team.fromJSON(localTeam.toJSON());
            newTeam.teamName = value;
            setLocalTeam(newTeam);
        }
    }

    var snackbarProps = new CustomSnackbarProps(showAlertValues.show, handleCloseAlert, showAlertValues.message, showAlertValues.severity);

    const numRows = Math.ceil(measurementIds.length / 4);
    const rows = [];
    for (let i = 0; i < numRows; i++) {
        const start = i * 4;
        const end = start + 4;
        rows.push(measurementIds.slice(start, end));
    }

    return (
        <div style={isLoading ? { pointerEvents: "none", opacity: "0.4" } : {}}>
            {isLoading ? <LoadingSpinner inDashboard={true} /> : <div></div>}

            <Box className="border rounded p-3 bg-light" component="form" onSubmit={isEditTeam ? handleSave : handleSubmit}>

                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Box sx={{ display: 'flex', marginBottom: '20px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                            <Typography mb={1} ml={3} variant="h5">Team Information</Typography>
                        </Box>
                        <IconButton onClick={() => toggleDialog()} style={{ position: 'relative', top: '-12px', right: '-12px' }}>
                            <FontAwesomeIcon icon={faCircleInfo} style={{ fontSize: '16px' }} />
                        </IconButton>
                    </Box>
                </Box>

                <Box sx={{ marginBottom: "40px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TextField id="outlined-basic" label={"Team Name"} size="small" type="text" variant="outlined" required
                        value={isEditTeam ? newTeamName : localTeam.teamName} sx={{ ...boxStyles, marginRight: 1, marginTop: 1 }} onChange={(e) => handleNameChange(e.target.value)} />

                    {isEditTeam && <Button variant="primary" type="submit" disabled={newTeamName === localTeam.teamName} >Save</Button>}
                </Box>


                {rows.map((row, rowIndex) => (
                    <Box key={`row-${rowIndex}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px', marginRight: "25px" }}>
                        {row.map((id, index) => (
                            <Box key={`row-${rowIndex}-item-${index}`} style={{ marginLeft: "30px" }}>
                                {dropdownInput(id)}
                            </Box>
                        ))}
                    </Box>
                ))}

                {!isEditTeam && <Container className="d-flex justify-content-center p-10px mb-4 mt-4" >
                    <Button variant="primary" type="submit">Create Team</Button>
                </Container>}

                <CustomSnackbar {...snackbarProps} />

                <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                    <DialogTitle>{"Details"}</DialogTitle>
                    <DialogContent>These are the devices you use to capture each measurement. They will be automatically filled in when uploading new data.</DialogContent>
                </Dialog>
            </Box>
        </ div >
    );
};

export default CreateTeam;