import React, { useState, useEffect, useContext, useRef } from 'react';
import { Table, Form, Button, Row, Col } from 'react-bootstrap';
import TeamContext from '../contexts/TeamContext.js';
import DataViewerContext from '../contexts/DataViewerContext.js';
import LoadingSpinner from '../../components/LoadingSpinner';
import NoTeamPlaceHolder from '../../components/NoTeamPlaceHolder'
import { useNavigate } from "react-router-dom";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concat } from "lodash";
import { getLensDataPaginateAPI } from "../../api/LensDataAPI.js"

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField, IconButton } from '@mui/material';
import ConfirmationDialog, { ConfirmationDialogProps } from '../../components/ConfirmationDialog';
import CustomSnackbar, { CustomSnackbarProps } from '../../components/Snackbar';

import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { deleteLensDataAPI } from '../../api/LensDataAPI.js';

import dayjs from 'dayjs';
import LensData from '../../models/lens_data.js';

//import { Auth, Storage } from 'aws-amplify';

function DataViewer() {
    const { team, setTeam } = useContext(TeamContext);
    const { dataViewerState, setDataViewerState } = useContext(DataViewerContext);
    const [loading, setLoading] = useState(false)
    const [noDataInitially, setNoDataInitially] = useState(false)
    const [itemToDelete, setItemToDelete] = useState(null);
    const isFirstRender = useRef(true);
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showAlertValues, setShowAlertValues] = useState({ show: false });

    var boxStyles = {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.7)'
        },
        '& .MuiInputLabel-shrink': {
            color: 'rgba(0, 0, 0, 0.8)',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.5)',
        }
    }

    useEffect(() => {
        if ((!isFirstRender.current && team)) {
            // When the team is changed, reset the data viewer context
            setNoDataInitially(false);
            setDataViewerState({ data: [], lastEvaluatedKey: null, filters: { patientId: "", patientDob: null, vault: false } });
            fetchData("", null, false, null, { initial: true });
        }
        else if ((isFirstRender.current && dataViewerState.data.length === 0 && filtersAreEmpty()) ||
            (!isFirstRender.current && team)) {
            setNoDataInitially(false);
            fetchData(dataViewerState.filters.patientId, dataViewerState.filters.patientDob, dataViewerState.filters.vault, dataViewerState.lastEvaluatedKey, { initial: true });
        }

        isFirstRender.current = false;
    }, [team]);

    const handleEdit = (item) => {
        navigate('/dashboard/data-editor', { state: item })
    }

    const handleDelete = (item) => {
        setItemToDelete(item);
        setShowConfirmation(true);
    }

    const handleCloseAlert = (event, reason) => {
        reason === 'clickaway' || setShowAlertValues({ show: false });
    };

    const doDelete = () => {
        if (itemToDelete != null) {
            setLoading(true);

            deleteLensDataAPI(itemToDelete.uid, itemToDelete.hasPdf).then((successful) => {
                if (successful) {
                    var newDataViewerState = { ...dataViewerState }
                    const index = newDataViewerState.data.findIndex((obj) => obj.uid === itemToDelete.uid);

                    if (index > -1) { // only splice array when item is found
                        newDataViewerState.data.splice(index, 1); // 2nd parameter means remove one item only
                    }

                    setDataViewerState(newDataViewerState);
                    setItemToDelete(null);

                    setShowAlertValues({ show: true, message: "Deleted", severity: "success" });

                }
                else {
                    setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });
                }

                setLoading(false);
            }).catch((err) => {
                console.log(err)
                setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });
                setLoading(false);
            });
        }
    }

    const confirmationDialogProps = new ConfirmationDialogProps(setShowConfirmation, showConfirmation, doDelete,
        "Confirm Delete", "Are you sure you want to delete this data?", "Delete");

    const filtersAreEmpty = () => {
        return !dataViewerState.filters.patientId &&
            !dataViewerState.filters.patientDob &&
            !dataViewerState.filters.vault;
    };

    async function fetchData(patientId, patientDob, vault, lastEvaluatedKey, { initial = false, append = false } = {}) {

        if (team) {
            setLoading(true);

            try {

                var [newLensData, newLastEvaluatedKey] = await getLensDataPaginateAPI(team?.uid, patientId, patientDob, vault, lastEvaluatedKey);

                if (initial && newLensData.length === 0) {
                    setNoDataInitially(true);
                }

                if (append) {
                    newLensData = concat(dataViewerState.data, newLensData)
                }

                // const newDataViewerState = { ...dataViewerState };
                // newDataViewerState.data = newLensData;
                const newDataViewerState = {
                    data: newLensData, lastEvaluatedKey: newLastEvaluatedKey, filters: { patientId: patientId, patientDob: patientDob, vault: vault }
                };

                setDataViewerState(newDataViewerState);
            } catch (error) {
                console.error(error);
            }

            setLoading(false);
        }
    }

    function handleLoadMore() {
        fetchData(dataViewerState.filters.patientId, dataViewerState.filters.patientDob, dataViewerState.filters.vault, dataViewerState.lastEvaluatedKey, { append: true });
    }

    function handleSearch(event) {
        event.preventDefault();

        const newDataViewerState = { ...dataViewerState };
        newDataViewerState.lastEvaluatedKey = null;
        setDataViewerState(newDataViewerState);

        fetchData(dataViewerState.filters.patientId, dataViewerState.filters.patientDob, dataViewerState.filters.vault, null);

    }

    function handleClear(event) {
        event.preventDefault();
        console.log(dataViewerState.filters.patientDob)

        if (dataViewerState.filters.patientId !== "" || dataViewerState.filters.patientDob !== null || dataViewerState.filters.vault) {
            const newDataViewerState = { ...dataViewerState };
            newDataViewerState.filters.patientId = "";
            newDataViewerState.filters.patientDob = null;
            newDataViewerState.filters.vault = false;
            newDataViewerState.lastEvaluatedKey = null;

            setDataViewerState(newDataViewerState);

            fetchData("", "", false, null);
        }
    }

    // async function viewPdf(uid) {
    //     try {
    //         //const url = await Storage.get(`staar_pdfs/${uid}.pdf`);
    //         const pdfKey = 'staar_pdfs/08d6b95697e9.pdf';
    //         const pdfUrl = await Storage.get(pdfKey,
    //             {
    //                 download: true,
    //                 headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
    //             });
    //         window.open(pdfUrl);
    //     } catch (error) {
    //         console.log("Error fetching PDF from S3: ", error);
    //     }
    // }

    // <td>
    //     {item.hasPdf ? (
    //         <Button variant="secondary" onClick={() => viewPdf(item.uid)}>View</Button>
    //     ) : (
    //         "None"
    //     )}
    // </td>

    const handleUpdateFilterState = (attribute, value) => {
        const newDataViewerState = { ...dataViewerState };
        newDataViewerState.filters[attribute] = value;

        setDataViewerState(newDataViewerState);
    }

    const handleDob = (value) => {
        const date = dayjs(value);
        value = date.format('YYYY/MM/DD');

        handleUpdateFilterState("patientDob", value);
    }

    const iconTable = () => {
        return (
            <Table style={{ width: "100px" }}>
                <thead>
                    <tr>
                        <th style={{ borderColor: "transparent" }}>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {dataViewerState.data.map((item) => (
                        <tr key={item.uid}>
                            <td style={{ borderColor: "transparent", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <IconButton onClick={() => handleEdit(item)} style={{ marginRight: "10px" }}>
                                    <FontAwesomeIcon icon={faEdit} style={{ fontSize: '18px' }} />
                                </IconButton>
                                <IconButton onClick={() => handleDelete(item)} >
                                    <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: '18px' }} />
                                </IconButton>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }

    var snackbarProps = new CustomSnackbarProps(showAlertValues.show, handleCloseAlert, showAlertValues.message, showAlertValues.severity);

    return (
        !team ? <NoTeamPlaceHolder /> :
            <div style={loading ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                {loading ? <LoadingSpinner inDashboard={true} /> : <div></div>}
                {!noDataInitially || loading ?
                    <div>
                        <ConfirmationDialog {...confirmationDialogProps} />
                        <div className="mb-4 mt-2 d-flex justify-content-center">
                            <div className="border rounded p-3 bg-light" style={{ width: "60%" }}>
                                <Form onSubmit={handleSearch}>
                                    <Row className="mb-3 align-items-end">
                                        <Col>
                                            <TextField id="outlined-basic" label={"Patient ID"} size="small" type="text" variant="outlined"
                                                value={dataViewerState.filters.patientId} sx={boxStyles} onChange={(e) => handleUpdateFilterState("patientId", e.target.value)} />
                                        </Col>
                                        <Col>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label={"Patient DOB"}
                                                    value={dataViewerState.filters.patientDob}
                                                    onChange={(e) => handleDob(e)}
                                                    renderInput={(params) => (<TextField {...params} size="small" sx={boxStyles} required={false} error={false} />)}
                                                />
                                            </LocalizationProvider>

                                        </Col>
                                        <Col xs="auto">
                                            <Button variant="primary" type="submit" style={{ height: '38px' }}>{filtersAreEmpty() ? "Refresh" : "Search"}</Button>
                                        </Col>
                                        <Col xs="auto">
                                            <Button variant="light" type="button" onClick={handleClear}>Clear</Button>
                                        </Col>
                                    </Row>
                                    <div className="d-flex justify-content-end" style={{ marginRight: "49%" }}>
                                        <Form.Check type="checkbox" id="incompleteCheckbox" value={dataViewerState.filters.vault}
                                            checked={dataViewerState.filters.vault} onChange={(e) => handleUpdateFilterState("vault", e.target.checked)} />
                                        <div style={{ cursor: "pointer" }}>
                                            <Form.Label htmlFor="incompleteCheckbox" style={{ marginLeft: "0.5rem", marginBottom: "0" }}>
                                                Missing Vault Only
                                            </Form.Label>
                                        </div>
                                    </div>

                                </Form>
                            </div>
                        </div>

                        <div className="border rounded p-3 bg-light">
                            <div className="d-flex flex-row">

                                <div className="ms-3" ></div>

                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Patient ID</th>
                                            <th>Patient DOB</th>
                                            <th>Eye</th>
                                            <th>Vault</th>
                                            <th>OCOS PDF</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataViewerState.data?.map((item) => (
                                            <tr key={item.uid} onDoubleClick={() => handleEdit(item)}>
                                                <td>{item.patientId}</td>
                                                <td>{item.patientDob ? item.patientDobtoString : "None"}</td>
                                                <td>{item.eye ? item.eye : "None"}</td>
                                                <td>{item.vault ? item.vault : "Missing"}</td>
                                                <td>{item.hasPdf ? "Uploaded" : "None"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                {iconTable()}

                            </div>

                            {dataViewerState.lastEvaluatedKey != null &&
                                <div className="d-flex justify-content-center">
                                    <Button variant="secondary" onClick={handleLoadMore}>Load more</Button>
                                </div>}
                        </div>
                    </div> :
                    <div className="center d-flex flex-column justify-content-center align-items-center h-100 border border-secondary rounded p-5">
                        <p className="text-center mb-4 fs-4">This team has no uploaded data</p>
                        <Button variant="secondary" className="btn btn-primary px-5 py-3 rounded-pill" onClick={() => navigate('/dashboard/data-uploader')} >
                            <FontAwesomeIcon icon={faCloudUploadAlt} style={{ marginRight: "1rem" }} />
                            Upload Data
                        </Button>
                    </div>
                }

                <CustomSnackbar {...snackbarProps} />
            </div>

    );
}

export default DataViewer;