import { Container } from 'react-bootstrap';

function Footer() {
    return (
        <footer style={{ paddingTop: "1rem" }}>
            <Container>
                <p style={{ textAlign: "center" }}>
                    © {new Date().getFullYear()} <a>Optica, Inc.</a>
                </p>
            </Container>
        </footer>
    );
}

export default Footer;
