import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from "react-router-dom";
import { useContext, useRef, useEffect, useCallback } from 'react';
import UserContext from '../../contexts/UserContext.js';
import TeamContext from '../../contexts/TeamContext.js';
import DataViewerContext from '../../contexts/DataViewerContext.js';
import User from '../../../models/user.js';
import { Navbar, Nav, Button, Dropdown } from 'react-bootstrap';
import { navLinks } from "../../../app/App.js"

function Header() {
    const { user, setUser } = useContext(UserContext);
    const { team, setTeam } = useContext(TeamContext);
    const { dataViewerState, setDataViewerState } = useContext(DataViewerContext);
    const location = useLocation();
    let navigate = useNavigate();
    let currentPath = location.pathname;
    let brand = "";
    let teamNamesMap = user.teamNamesMap;
    const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // 15 minutes in milliseconds
    const timer = useRef(null);

    const initializeTimer = () => {
        timer.current = setTimeout(signOut, INACTIVITY_TIMEOUT);
    };

    const resetTimer = useCallback(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        initializeTimer();
    }, []);

    const onUserActivity = () => {
        resetTimer();
    };

    useEffect(() => {
        // Set up event listeners for mouse and keyboard events
        window.addEventListener('mousemove', onUserActivity);
        window.addEventListener('keydown', onUserActivity);

        initializeTimer(); // Initialize the timer

        // Clean up event listeners on unmount
        return () => {
            window.removeEventListener('mousemove', onUserActivity);
            window.removeEventListener('keydown', onUserActivity);
            clearTimeout(timer.current);
        };
    }, [resetTimer]);

    const signOut = async () => {
        Auth.signOut()
            .then(data => console.log(data))
            .catch(err => console.log(err));

        // Reset states
        setUser(new User());
        setTeam(null);
        setDataViewerState({ data: [], lastEvaluatedKey: null, filters: { patientId: "", patientDob: null, vault: false } });

        navigate("/", { replace: true });

    }

    const handleSelect = (eventKey) => {
        setTeam(user.teamObjectsMap[eventKey]);
    };

    const dropdownItems = Object.keys(teamNamesMap).map((uid) => (
        <Dropdown.Item key={uid} eventKey={uid} active={teamNamesMap[uid] === team?.teamName}
            style={{ backgroundColor: teamNamesMap[uid] === team.teamName ? "#E1E3F6" : "transparent", color: "#000" }}>
            {teamNamesMap[uid]}
        </Dropdown.Item>
    ));

    navLinks.forEach((link) => {
        if (link.to === currentPath) {
            brand = link.label;
        }
    });

    const navigateCreateTeam = () => {
        navigate('/dashboard/create-team')
    };

    const createTeamButton = () => {
        return (
            <Button variant="outline-light" style={{ marginLeft: '20px', fontSize: '18px', color: '#0D0D0D' }} onClick={navigateCreateTeam}
                onMouseEnter={(e) => e.target.style.color = '#000000'}
                onMouseLeave={(e) => e.target.style.color = '#0D0D0D'}>Create Team</Button>
        );
    }

    //<Navbar.Brand style={{ paddingLeft: '36px', color: '#2A2A2A', fontSize: '27px' }}>{brand}</Navbar.Brand>

    // Will use this in the future for custom contact us page.
    // <Nav.Link href="#contact" style={{ marginRight: '15px', fontSize: '18px', color: '#585858' }}
    //     onMouseEnter={(e) => e.target.style.color = '#000000'}
    //     onMouseLeave={(e) => e.target.style.color = '#585858'}>Contact</Nav.Link>

    return <Navbar expand="lg" style={{ height: '65px', borderBottom: 'solid 1px #ccc', backgroundColor: '#F6F6F6' }}>
        {team &&
            <Dropdown onSelect={handleSelect} style={{ marginLeft: '36px', marginRight: '20px' }}>
                <Dropdown.Toggle variant="light" id="team-dropdown" style={{ fontSize: '18px', backgroundColor: '#F6F6F6' }}>
                    {team.teamName || 'Select Team'}
                </Dropdown.Toggle>
                <Dropdown.Menu>{dropdownItems}</Dropdown.Menu>
            </Dropdown>
        }
        {(currentPath === "/dashboard/team-manager" || !team) && createTeamButton()}
        {currentPath === "/dashboard/data-editor" &&
            <div className="d-flex align-items-center justify-content-center w-100">
                <Navbar.Brand style={{ marginLeft: '50px', color: '#2A2A2A', fontSize: '27px' }}>Data Editor</Navbar.Brand>
            </div>}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav style={{ paddingRight: '30px' }}>
                <Button variant="outline-light" style={{ marginRight: '15px', fontSize: '18px', color: '#585858' }} onClick={() => { window.location.href = 'mailto:jpetrisko@berkeley.edu?subject=EVO Calculator Inquiry'; }}
                    onMouseEnter={(e) => e.target.style.color = '#000000'}
                    onMouseLeave={(e) => e.target.style.color = '#585858'}>Contact</Button>
                <Button variant="outline-light" style={{ marginLeft: '20px', fontSize: '18px', color: '#585858' }} onClick={signOut}
                    onMouseEnter={(e) => e.target.style.color = '#000000'}
                    onMouseLeave={(e) => e.target.style.color = '#585858'}>Logout</Button>
            </Nav>
        </Navbar.Collapse>
    </Navbar>


}

export default Header;