import React, { useState, useEffect, useContext, useRef } from 'react';
import { TextField, Box, Autocomplete, Typography } from '@mui/material';
import { Button } from "react-bootstrap";
import UserContext from '../contexts/UserContext.js';
import User from '../../models/user.js'
import { updateUserAPI } from '../../api/UserAPI.js'
import CustomSnackbar, { CustomSnackbarProps } from '../../components/Snackbar';
import LoadingSpinner from '../../components/LoadingSpinner';
import ErrorPage from '../../components/ErrorPage';

function Profile() {
  const { user, setUser } = useContext(UserContext);
  const [newUser, setNewUser] = useState(User.fromJSON({ json: user.toJSON() }));
  const [userUpdates, setUserUpdates] = useState({});
  const [showAlertValues, setShowAlertValues] = useState({ show: false });
  const [saving, setSaving] = useState(false);

  var boxStyles = {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.7)'
    },
    '& .MuiInputLabel-shrink': {
      color: 'rgba(0, 0, 0, 0.8)',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.5)',
    },
    '& .MuiInputLabel-asterisk': {
      color: '#E28B00',
      fontSize: '1rem',
      fontWeight: 'bold',
    }
  }

  const handleCloseAlert = (event, reason) => {
    reason === 'clickaway' || setShowAlertValues({ show: false });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setSaving(true);

    updateUserAPI({ ...userUpdates }).then((successful) => {
      if (successful) {
        setShowAlertValues({ show: true, message: "Saved", severity: "success" });
        setUser(newUser);
        setUserUpdates({});
      }
      else {
        setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });
      }

      setSaving(false);
    }).catch((err) => {
      console.log(err)
      setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });
      setSaving(false);
    });
  }

  const handleNameChange = (value, attribute) => {
    const newUserCopy = User.fromJSON({ json: newUser.toJSON() });
    const newUserUpdates = { ...userUpdates };

    newUserCopy[attribute] = value;
    newUserUpdates[attribute] = value;

    if (value === user[attribute]) {
      delete newUserUpdates[attribute];
    }

    setNewUser(newUserCopy);
    setUserUpdates(newUserUpdates);
  }

  var snackbarProps = new CustomSnackbarProps(showAlertValues.show, handleCloseAlert, showAlertValues.message, showAlertValues.severity);

  return (
    !user.uid ? <ErrorPage /> :
      <Box style={saving ? { pointerEvents: "none", opacity: "0.4" } : {}} >
        {saving && <LoadingSpinner inDashboard={true} />}

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
          <Box className="border rounded p-3 bg-light" sx={{ display: 'flex', alignItems: 'center', flexDirection: "column", justifyContent: 'center', width: '60%' }}
            component="form" onSubmit={handleSubmit}>
            <Typography mb={4} variant="h5">Profile Information</Typography>

            <Box display="flex">
              <Autocomplete disablePortal clearOnEscape={true} options={user.prefixes} value={newUser.namePrefix || null}
                onChange={(event, value) => handleNameChange(value ? value : "", "namePrefix")}
                renderInput={(params) => <TextField {...params} size="small" label="Prefix" sx={{ ...boxStyles, minWidth: 120 }} />} />

              <TextField id="outlined-basic" label={"First Name"} size="small" type="text" variant="outlined" required
                value={newUser.firstName} sx={{ ...boxStyles, marginLeft: 1 }} onChange={(e) => handleNameChange(e.target.value, "firstName")} />

              <TextField id="outlined-basic" label={"Last Name"} size="small" type="text" variant="outlined" required
                value={newUser.lastName} sx={{ ...boxStyles, marginLeft: 1 }} onChange={(e) => handleNameChange(e.target.value, "lastName")} />
            </Box>

            <Box mt={1}>
              <Button variant="primary" type="submit" disabled={Object.keys(userUpdates).length === 0}>
                Save
              </Button>
            </Box>


            <CustomSnackbar {...snackbarProps} />
          </Box>
        </Box>
      </Box >
  );

}

export default Profile;