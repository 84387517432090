import React, { useState, useRef, useContext, useEffect } from 'react';
import { Container, Button } from "react-bootstrap";
import { TextField, Box, Checkbox, FormControlLabel, IconButton, Tooltip, Autocomplete, Typography, Dialog, DialogTitle, DialogContent, FormControl, RadioGroup, Radio, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faCircleInfo, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from "react-router-dom";

import DataViewerContext from '../contexts/DataViewerContext.js';
import UserContext from '../contexts/UserContext.js';
import TeamContext from '../contexts/TeamContext.js';
import LensData from '../../models/lens_data.js';
import LoadingSpinner from '../../components/LoadingSpinner';
import ConfirmationDialog, { ConfirmationDialogProps } from '../../components/ConfirmationDialog';
import CustomSnackbar, { CustomSnackbarProps } from '../../components/Snackbar';
import ErrorPage from '../../components/ErrorPage';
import NoTeamPlaceHolder from '../../components/NoTeamPlaceHolder'

import { parseLensDataPdfAPI, createLensDataAPI, updateLensDataAPI } from '../../api/LensDataAPI.js';

import dayjs from 'dayjs';
import { ExpandMore } from '@mui/icons-material';


function DataUploader() {
    const location = useLocation();
    const itemToUpdate = location.state;
    const isEditItem = itemToUpdate ? true : false;
    const { team, setTeam } = useContext(TeamContext);
    const { user, setUser } = useContext(UserContext);
    const { dataViewerState, setDataViewerState } = useContext(DataViewerContext);
    const [lensDataUpdates, setLensDataUpdates] = useState({});
    const [submitting, setSubmitting] = useState(false)
    const [parsing, setParsing] = useState(false)
    const [file, setFile] = useState(null);
    const [showPdf, setShowPdf] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContentId, setDialogContentId] = useState(0);
    const fileInputRef = useRef(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showParseConfirmation, setShowParseConfirmation] = useState(false);
    const [savePdf, setSavePdf] = useState(true);
    const [initialized, setInitialized] = useState(false);
    const [errorMap, setErrorMap] = useState({});
    const [showAlertValues, setShowAlertValues] = useState({ show: false });
    const requiredIds = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "14", "15", "16", "35", "36"]
    const getFreshLensData = () => new LensData({ ...(team?.deviceIds ?? {}), teamUid: team?.uid ?? "", uploaderUid: user.uploaderUid, });
    const [lensData, setLensData] = useState(isEditItem ? LensData.fromJSON(itemToUpdate) : getFreshLensData());

    const noInputColor = '#C1C1C1'
    const hasInputColor = '#292929'
    const atColor = 'rgba(0, 0, 0, 0.9)'
    const formSubmitButtonText = isEditItem ? "Update" : "Upload";
    const showClearFormButton = !isEditItem;

    const dialogContent = {
        0: "Automatically fill out portions of this form by parsing an OCOS PDF containing the preoperative measurements of your patient's eye.",
        1: "To comply with HIPAA guidelines, it is important to avoid including direct patient information in the Patient ID. Patient ID and Patient DOB allow you to search for records in Data Viewer. We use Patient DOB to calculate your patient's age.",
        2: "Data relating to the implanted lens. It is important to provide an accurate vault and device used to measure the vault. Lens Size, Sphere, Cylinder, and Axis must reflect the implanted lens.",
        3: "Preoperative measurements of your patient's eye. These are the same measurements provided to OCOS, but we also want to document which device was used to perform each measurement. Parsing an OCOS PDF will automatically populate these fields.",
        4: "Additional preoperative data important to our research. Please provide these values if available.",
    }

    var boxStyles = {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.7)'
        },
        '& .MuiInputLabel-shrink': {
            color: 'rgba(0, 0, 0, 0.8)',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.5)',
        },
        '& .MuiInputLabel-asterisk': {
            color: '#E28B00',
            fontSize: '1.4rem',
            fontWeight: 'bold',
        },
    }

    // When the team is changed in the navbar, update the team used here.
    useEffect(() => {
        // Check if initialized, otherwise, the team's current devices are set 
        // rather than the data point's machines. For some reason, this hook runs
        // when the data editor is initialized even though setTeam is not called.
        if (initialized) {
            const newLensData = LensData.fromJSON(lensData.toJSON());
            const attributesToCopy = ["kDeviceId", "acdDeviceId", "cctDeviceId", "wtwDeviceId"];
            const newLensDataUpdates = { ...lensDataUpdates }

            // Do not copy over machines when changing teams during data edit, for now.
            if (!isEditItem) {
                for (var attribute of attributesToCopy) {
                    const newValue = team ? team[attribute] : "";

                    if (isEditItem) {
                        lensDataUpdateHelper(newValue, attribute, newLensDataUpdates)
                    }

                    newLensData[attribute] = newValue;
                }
            }

            if (isEditItem) {
                lensDataUpdateHelper(team?.uid, "teamUid", newLensDataUpdates)
                setLensDataUpdates(newLensDataUpdates);
            }

            newLensData["teamUid"] = team?.uid;

            if (!isEditItem) {
                newLensData["uploaderUid"] = user.uid;
            }

            setLensData(newLensData);
        }

        setInitialized(true);

    }, [team]);

    // Used to clear the from when navigating from edit to data uploader
    useEffect(() => {
        if (location.state === null) {
            setLensData(new LensData(
                {
                    ...team?.deviceIds,
                    teamUid: team?.uid ?? "",
                    uploaderUid: user.uploaderUid
                }
            ));
        }

    }, [location]);


    const handleCloseAlert = (event, reason) => {
        reason === 'clickaway' || setShowAlertValues({ show: false });
    };

    var snackbarProps = new CustomSnackbarProps(showAlertValues.show, handleCloseAlert, showAlertValues.message, showAlertValues.severity);

    const lensDataUpdateHelper = (newValue, attribute, newLensDataUpdates) => {
        // Save the edit if it is different than the original.
        // Otherwise, remove it from the updates map.
        if (newValue !== location.state[attribute]) {
            newLensDataUpdates[attribute] = newValue;
        }
        else {
            delete newLensDataUpdates[attribute];
        }
    }

    const toggleDialog = (id) => {
        setDialogOpen(true);
        setDialogContentId(id);
    }

    const clearAll = () => {
        handleClearPdf();
        clearForm();
    }

    const clearForm = () => {
        setSavePdf(true);
        setErrorMap({});
        setLensData(new LensData(
            {
                ...team?.deviceIds,
                teamUid: team?.uid,
                uploaderUid: user.uploaderUid
            }
        ));
    }

    const validateForm = () => {
        return Object.values(errorMap).every(value => !value);
    };

    const handleSubmit = (event) => {
        event.preventDefault()

        if (validateForm()) {
            setShowConfirmation(true);
        }
        else {
            setShowAlertValues({ show: true, message: "Some values are out of range.", severity: "warning" });
        }
    };

    const executeDataUpload = async (lensDataToUpload, { base64EncodedFile = null } = {}) => {
        setSubmitting(true);

        if (isEditItem) {
            if (base64EncodedFile) {
                lensDataUpdates["pdf"] = base64EncodedFile;
                lensDataUpdates["hasPdf"] = true;
            }

            updateLensDataAPI({ ...lensDataUpdates }, lensData.uid).then((successful) => {

                if (successful) {
                    setShowAlertValues({ show: true, message: "Updated", severity: "success" });

                    // Update LensData in the data viewer state
                    const index = dataViewerState.data.findIndex((obj) => obj.uid === lensData.uid);
                    var newDataViewerState = { ...dataViewerState }
                    newDataViewerState.data[index] = LensData.fromJSON(lensData.toJSON());

                    setDataViewerState(newDataViewerState);
                    setLensDataUpdates({});
                }
                else {
                    setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });
                }

                setSubmitting(false);
            }).catch((err) => {
                console.log(err)
                setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });
                setSubmitting(false);
            });
        }
        else {
            createLensDataAPI(lensDataToUpload, base64EncodedFile).then((response) => {
                if (response.successful) {
                    setShowAlertValues({ show: true, message: "Uploaded", severity: "success" });

                    // Assign UID from API to local lensData object
                    lensDataToUpload.uid = response.uid;
                    setLensData(lensDataToUpload);

                    // Add new lensData to data viewer state if data viewer has data
                    if (dataViewerState.data.length > 0) {
                        var newDataViewerState = { ...dataViewerState }
                        newDataViewerState.data.unshift(LensData.fromJSON(lensDataToUpload.toJSON()));
                        setDataViewerState(newDataViewerState);
                    }

                    clearAll();
                }
                else {
                    setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });
                }

                setSubmitting(false);
            }).catch((err) => {
                console.log(err)
                setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });
                setSubmitting(false);
            });
        }
    }

    const submit = async () => {
        const newLensData = LensData.fromJSON(lensData.toJSON());

        // Don't save vault info if no lens is uploaded.
        // This can happen when a user selects lens, enters vault, unselects lens.
        newLensData.clearPostOpDataIfNoLens();
        setLensData(newLensData);

        if (savePdf && file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                const base64EncodedFile = reader.result.split("data:application/pdf;base64,")[1];

                newLensData.hasPdf = true;
                setLensData(newLensData);

                executeDataUpload(newLensData, { base64EncodedFile: base64EncodedFile });
            }
        }
        else {
            executeDataUpload(newLensData);
        }
    };

    const executeParse = (replace) => {
        setParsing(true);

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
            const base64EncodedFile = reader.result.split("data:application/pdf;base64,")[1];

            parseLensDataPdfAPI(base64EncodedFile).then((parsedLensData) => {
                if (parsedLensData != null) {
                    const newLensData = LensData.fromJSON(lensData.toJSON());
                    var newErrorMap = { ...errorMap };
                    var allValuesAreNull = true;

                    for (const [key, value] of Object.entries(parsedLensData)) {
                        if ((value || value === 0) && (replace || (!replace && !newLensData[key]))) {
                            newLensData[key] = value;

                            const id = newLensData.attributeToId(key);
                            if (id !== undefined) {
                                newErrorMap[id] = !newLensData.validate(id);
                            }

                            if (key !== "uid" && key !== "successfullyParsed" && newLensData[key] !== null) {
                                allValuesAreNull = false;
                            }
                        }
                    }

                    if (allValuesAreNull) {
                        setShowAlertValues({ show: true, message: "Invalid OCOS PDF", severity: "error" });
                    }
                    else if (!parsedLensData.successfullyParsed) {
                        setShowAlertValues({ show: true, message: "Some values could not be extracted", severity: "warning" });
                    }

                    setErrorMap(newErrorMap);
                    setLensData(newLensData);
                }
                else {
                    setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });
                }

                setParsing(false);
            }).catch((err) => {
                console.log(err)
                setParsing(false);
            });
        };
    }

    const executeParseKeep = () => {
        executeParse(false);
    }

    const executeParseReplace = () => {
        executeParse(true);
    }


    var confirmationDialogProps = new ConfirmationDialogProps(setShowConfirmation, showConfirmation, submit,
        isEditItem ? "Confirm Update" : "Confirm Upload",
        isEditItem ? `Are you sure you want to make these changes` + (("teamUid" in lensDataUpdates) ? ` and move this data to team: ${team?.teamName}?` : "?")
            : `Are you sure you want to upload this data to team: ${team?.teamName}?`,
        formSubmitButtonText);

    if (showParseConfirmation) {
        confirmationDialogProps = new ConfirmationDialogProps(setShowParseConfirmation, showParseConfirmation, executeParseReplace,
            "Confirm Parse", `Would you like to replace these existing values?\n\n${lensData.overwriteableValuesToString}`, "Replace", "Keep", executeParseKeep);
    }

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    }

    const togglePdf = () => {
        setShowPdf(!showPdf);
    }

    const handleClearPdf = () => {
        setFile(null);
        fileInputRef.current.value = null;
    }

    const handleFileParse = async (event) => {
        event.preventDefault()

        if (lensData.getOverwriteableValues.length > 0) {
            setShowParseConfirmation(true);
        }
        else {
            executeParseReplace();
        }
    };

    const handleBlur = (id, value) => {
        var newErrorMap = { ...errorMap }

        if (value) {
            newErrorMap[id] = !lensData.validate(id);
        }
        else {
            delete newErrorMap[id];
        }

        setErrorMap(newErrorMap);
    }

    const dropDownValue = (id) => {
        var deviceName = null;
        var attribute = lensData.measurementIdToDeviceIdAttribute[id];
        var deviceId = lensData[attribute]

        if (deviceId != null) {
            deviceName = lensData.deviceIdToName(deviceId);
        }

        return deviceName;
    }

    const updateLensData = (attribute, newValue) => {
        if (isEditItem) {
            const newLensDataUpdates = { ...lensDataUpdates }

            lensDataUpdateHelper(newValue, attribute, newLensDataUpdates)

            setLensDataUpdates(newLensDataUpdates);
        }

        const newLensData = LensData.fromJSON(lensData.toJSON());
        newLensData[attribute] = newValue;
        setLensData(newLensData);

    }

    const handleDropdownChange = (id, event, value) => {
        var attribute = lensData.measurementIdToDeviceIdAttribute[id];
        var deviceId = lensData.deviceNameToId(value);

        updateLensData(attribute, deviceId);
    };

    const handleInput = (id, value) => {
        const attribute = lensData.measurementIdToAttribute[id];

        if (id === "15") {
            const date = dayjs(value);
            value = date.format('YYYY/MM/DD');
        }

        if (attribute) {
            updateLensData(attribute, value);
        }
    };

    const getDropdownLabelAndTitle = (label, title, id) => {
        if (label === "Device") {
            label = lensData.measurementIdToDeviceLabel[id] || "";
            var name = lensData.measurementNameFromId(id);

            if (name === "K1") {
                name = "Keratometry";
                title = "Keratometry";
            }

            if (label === "") {
                label = `${name} Device`;
            }


            title = `${title} Device`
        }

        return [label, title];
    }

    const dropdownInput = (id, { label = "Device", minWidth = 200, maxWidth = null, ml = null } = {}) => {
        let deviceNames = lensData.deviceNamesForId(id);
        let measurement = lensData.measurementFromId(id);
        var title = lensData.measurementIdToTip[id];
        var value = dropDownValue(id);
        var hasValue = value !== "" && value !== null;
        var required = (requiredIds.includes(id) || (!!measurement && !lensData.requiredDropdownExceptions.includes(id)) && !lensData.deviceFromId(id));
        var deviceIdAttribute = lensData.measurementIdToDeviceIdAttribute[id];
        var isDevice = deviceIdAttribute.toLowerCase().includes("id");

        // if (["17", "19"].includes(id)) {
        //     required = required || (!!lensData.measurementFromId("13") && !lensData.measurementFromId(id));
        // }

        var newBoxStyles = {
            ...boxStyles, minWidth: minWidth,
            ml: ml,
            maxWidth: maxWidth,
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: hasValue ? hasInputColor : noInputColor,
                borderWidth: '1.5px'
            }
        };

        [label, title] = getDropdownLabelAndTitle(label, title, id);

        if (isDevice) {
            deviceNames.unshift("Request New Device");
        }

        return (
            <div>
                {deviceNames.length - 1 > 0 &&
                    <Autocomplete
                        disablePortal
                        clearOnEscape={true}
                        options={deviceNames}
                        value={value || null}
                        onChange={(event, value) => handleDropdownChange(id, event, value)}
                        renderOption={(props, option) => {
                            if (option === deviceNames[0] && isDevice) {
                                return (
                                    <Box className="d-flex justify-content-center align-items-center" key="email">
                                        <Button
                                            {...props}
                                            variant="outline-secondary" size="sm" style={{ justifyContent: "center", width: "95%", marginBottom: "10px", backgroundColor: '#6C6C6C', color: '#FFFFFF' }}
                                            onClick={() => { window.location.href = `mailto:jpetrisko@berkeley.edu?subject=${title} Request`; }}>
                                            {option}
                                        </Button>
                                    </Box>
                                )
                            } else {
                                return (<li {...props} key={option}>{option}</li>);
                            }
                        }}
                        renderInput={(params) =>
                            <Tooltip title={title} disableInteractive>
                                <TextField
                                    {...params}
                                    size="small"
                                    label={label}
                                    sx={newBoxStyles}
                                    InputLabelProps={{ shrink: true, required: required }}
                                    required={required}
                                />
                            </Tooltip>
                        }
                    />
                }
            </div>

        );
    }

    const radioInput = (id) => {
        const title = lensData.getRadioTitleFromId(id);
        const options = lensData.getRadioOptionsFromId(id);
        let measurement = lensData.measurementFromId(id);
        var required = requiredIds.includes(id);

        return (
            <Box>
                <Typography variant="h6" style={{ fontSize: '15px' }}>
                    {title} {required && <span style={{ color: 'red' }}>*</span>}
                </Typography>
                <RadioGroup
                    aria-label="radio-buttons"
                    name="radio-buttons-group"
                    value={measurement}
                    onChange={(e) => handleInput(id, e.target.value)}
                >
                    {options.map((option) => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={<span style={{ fontSize: '15px' }}>{option.label}</span>}
                            style={{ flexDirection: 'row', alignItems: 'center' }}
                        />
                    ))}
                </RadioGroup>
            </Box>
        );
    }



    const getStyleAndMeasurementFromId = (id, maxWidth) => {
        let measurement = lensData.measurementFromId(id);
        var hasValue = measurement !== "" && measurement !== null;
        var required = requiredIds.includes(id);

        // if (["18", "20"].includes(id)) {
        //     required = required || (!!lensData.measurementFromId("13") && !lensData.measurementFromId(id));
        // }

        return {
            measurement: measurement,
            required: required,
            boxStyles: {
                ...boxStyles,
                flex: '1',
                maxWidth: maxWidth,
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: hasValue ? hasInputColor : noInputColor,
                    borderWidth: '1.5px'
                },
                '& .MuiFormHelperText-root': { fontSize: '9px' }
            }
        };
    }

    const dateInput = (id) => {
        const { measurement, required, boxStyles } = getStyleAndMeasurementFromId(id, 150);

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        value={measurement}
                        onChange={(e) => handleInput(id, e)}
                        renderInput={(params) => (
                            <Tooltip title={lensData.measurementIdToTip[id]} disableInteractive>
                                <TextField
                                    {...params}
                                    size="small"
                                    label={lensData.measurementNameFromId(id)}
                                    sx={{ ...boxStyles, height: 40 }}
                                    onBlur={(e) => handleBlur(id, e.target.value)}
                                    InputLabelProps={{ shrink: true, required: required }}
                                    required={required}
                                    error={errorMap[id]}
                                    helperText={errorMap[id] ? lensData.getErrorMessageFromId(id) : ""}
                                />
                            </Tooltip>
                        )}
                    />
                </LocalizationProvider>
            </Box>
        );
    }

    const labelAndInput = (id, { maxWidth = 76 } = {}) => {
        const { measurement, required, boxStyles } = getStyleAndMeasurementFromId(id, maxWidth);

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                <Tooltip title={lensData.measurementIdToTip[id]} disableInteractive>
                    <TextField
                        label={lensData.measurementNameFromId(id)}
                        size="small"
                        variant="outlined"
                        value={measurement}
                        sx={{ ...boxStyles, height: 40 }}
                        InputLabelProps={{ shrink: true, required: required }}
                        onChange={(e) => handleInput(id, e.target.value)}
                        onBlur={(e) => handleBlur(id, e.target.value)}
                        required={required}
                        error={errorMap[id]}
                        helperText={errorMap[id] ? lensData.getErrorMessageFromId(id) : ""}
                    />
                </Tooltip>
            </Box>
        );
    }


    const inputAndDropdownRow = (ids) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '14px' }}>
                {ids.map((id, index) => (
                    <React.Fragment key={id}>
                        {labelAndInput(id)}
                        {dropdownInput(id)}
                        {index < ids.length - 1 && <Box ml={2}></Box>}
                    </React.Fragment>
                ))}
            </Box>
        );

    };

    const [expanded, setExpanded] = React.useState(false);

    const handlePanelChange = () => {
        setExpanded(!expanded);
    };

    const additionalInformationSection = () => {
        var activeDataArray = [];
        var inactiveDataArray = [];

        var activeSublist = [];
        var inactiveSublist = [];
        lensData.additionalDataIds.forEach((id) => {
            if (!!team[lensData.measurementIdToDeviceIdAttribute[id]]) {
                activeSublist.push(id);
            }
            else {
                inactiveSublist.push(id);
            }

            if (activeSublist.length == 3) {
                activeDataArray.push(activeSublist);
                activeSublist = [];
            }
            if (inactiveSublist.length == 3) {
                inactiveDataArray.push(inactiveSublist);
                inactiveSublist = [];
            }

        });

        if (activeSublist.length != 0) {
            activeDataArray.push(activeSublist)
        }
        if (inactiveSublist.length != 0) {
            inactiveDataArray.push(inactiveSublist)
        }

        return (
            <>
                {activeDataArray.map((ids, index) => (
                    <div key={index}>{inputAndDropdownRow(ids)}</div>
                ))}
                {<Box mt={3} mb={2}>
                    <Accordion expanded={expanded} onChange={handlePanelChange}
                        style={{
                            backgroundColor: "rgba(250, 250, 250, 1)",
                            boxShadow: 'none',
                            border: '1px solid rgba(0, 0, 0, 0.5)',
                            borderRadius: '8px 8px 8px 8px',
                        }}>
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel-content">
                            <Typography ml={3} variant="h6"
                                style={{
                                    fontSize: '15px',
                                    color: 'rgba(0, 0, 0, 0.7)',
                                    textAlign: 'center',
                                    width: '100%'
                                }}>Additional Measurements</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {inactiveDataArray.map((ids, index) => (
                                <div key={index}>{inputAndDropdownRow(ids)}</div>
                            ))}
                        </AccordionDetails>
                    </Accordion>

                </Box>
                }
            </>
        );
    };

    const formSection = (header, width, id, elements) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box sx={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid rgba(0, 0, 0, 0.8)',
                    borderRadius: '8px', padding: '16px', marginBottom: '20px', width: width,
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Box sx={{ display: 'flex', marginBottom: '20px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, marginLeft: '40px' }}>
                                <Typography variant="h7">{header}</Typography>
                            </Box>
                            <IconButton onClick={() => toggleDialog(id)} style={{ position: 'relative', top: '-12px', right: '-12px' }}>
                                <FontAwesomeIcon icon={faCircleInfo} style={{ fontSize: '16px' }} />
                            </IconButton>
                        </Box>
                        {elements}
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        !team ? <NoTeamPlaceHolder /> :
            (!lensData.uid && (isEditItem || location.pathname === "/dashboard/data-editor")) ? <ErrorPage /> :
                (
                    <div style={parsing || submitting ? { pointerEvents: "none", opacity: "0.4" } : {}} >
                        <ConfirmationDialog {...confirmationDialogProps} />
                        {(parsing || submitting) && <LoadingSpinner inDashboard={true} />}

                        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                            <DialogTitle>{"Details"}</DialogTitle>
                            <DialogContent>{dialogContent[dialogContentId]}</DialogContent>
                        </Dialog>

                        <Container className="border rounded p-3 mb-3 mt-2" style={{ backgroundColor: "rgba(250, 250, 250, 1)", width: showPdf ? "75%" : "60%", position: "relative" }}>
                            <Box className="d-flex justify-content-center align-items-center" sx={{ display: "flex", flexDirection: "column", width: "100%" }} >
                                <div style={{ position: "absolute", top: "5px", right: "7px" }}>
                                    <IconButton onClick={() => toggleDialog(0)}>
                                        <FontAwesomeIcon icon={faCircleInfo} style={{ fontSize: "16px" }} />
                                    </IconButton>
                                </div>

                                <Box sx={{ alignItems: "center", justifyContent: "center", flex: 1 }}>
                                    <Typography variant="h7">
                                        Populate Form Using an OCOS PDF
                                    </Typography>
                                </Box>

                                <div className="d-flex align-items-center">
                                    <div className="me-3">
                                        <input type="file" ref={fileInputRef} onChange={handleFileChange} accept="application/pdf" />
                                    </div>
                                    <div>
                                        {file && (<FontAwesomeIcon icon={faTrashAlt} className="trash-icon me-3" style={{ cursor: 'pointer' }} onClick={handleClearPdf} />)}
                                        <Button onClick={handleFileParse} variant="secondary" disabled={!file}>
                                            Parse
                                        </Button>
                                    </div>
                                </div>
                                {file && (
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Button onClick={togglePdf} variant="secondary" size="sm">
                                            {showPdf ? "Hide PDF" : "Show PDF"}
                                        </Button>
                                    </div>
                                )}

                                {file && showPdf && (
                                    <Container className="d-flex justify-content-center align-items-center border rounded p-3 mt-3 bg-light" style={{ position: "relative" }}>
                                        <iframe className="pdf-container border" src={URL.createObjectURL(file)} title="Selected PDF" />
                                    </Container>
                                )}
                            </Box>
                        </Container>


                        <Box className="bg-light rounded p-3" component="form" onSubmit={handleSubmit}>
                            <Container className="p-3 mb-3">
                                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                                    <Box textAlign="center" mb={3} ml={showClearFormButton ? 13 : 0} flex="1">
                                        <Typography variant="h5">Basic Data</Typography>
                                    </Box>

                                    {showClearFormButton && <Button size="sm" variant="secondary" onClick={() => clearForm()}>
                                        Clear Form
                                    </Button>}
                                </div>


                                {formSection("Patient Information", "80%", 1,
                                    <>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {labelAndInput("14", { maxWidth: 150 })}
                                            {dateInput("15")}
                                            {dropdownInput("16", { label: 'Eye', minWidth: 95, maxWidth: 95, ml: 1 })}
                                            {dropdownInput("35", { label: 'Sex', minWidth: 140, maxWidth: 140, ml: 1 })}
                                            {dropdownInput("36", { label: 'Ethnicity', minWidth: 250, maxWidth: 250, ml: 1 })}
                                        </Box>
                                    </>)}


                                {formSection("Preoperative", "90%", 3,
                                    <>
                                        <Box className="d-flex justify-content-center mb-3" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box>{labelAndInput("2")} </Box>
                                            <Box>{labelAndInput("3")} </Box>
                                            <Box component="span" sx={{ color: atColor }}> @ </Box>
                                            <Box>{labelAndInput("4")} </Box>
                                            <Box ml={8}>{labelAndInput("1")} </Box>
                                            <Box>{labelAndInput("12")} </Box>
                                        </Box>


                                        <Box className="d-flex justify-content-center mb-3" sx={{ display: 'flex', alignItems: 'center' }}>
                                            {labelAndInput("5")}
                                            <Box component="span" sx={{ color: atColor }}> @ </Box>
                                            {labelAndInput("6")}
                                            <Box component="span" sx={{ mx: 2 }}></Box>
                                            {labelAndInput("7")}
                                            <Box component="span" sx={{ color: atColor }}> @ </Box>
                                            {labelAndInput("8")}
                                            <Box component="span" sx={{ mx: 1 }}></Box>
                                            {dropdownInput("5")}
                                        </Box>

                                        {inputAndDropdownRow(["9", "10", "11"])}
                                    </>)}


                                {formSection("Surgical & Clinical", "90%", 2,
                                    <>
                                        <Box className="d-flex justify-content-center mb-3" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box ml={6} mr={1} >{
                                                dropdownInput("17", { label: 'Lens Size', minWidth: 115, ml: 0 })}
                                            </Box>
                                            <Box>{labelAndInput("18")} </Box>
                                            <Box mr={1}>{
                                                dropdownInput("19", { label: 'Cylinder', minWidth: 100, ml: 0 })}
                                            </Box>
                                            <Box component="span" sx={{ color: atColor }}> @ </Box>
                                            <Box>{labelAndInput("20")} </Box>
                                        </Box>

                                        {!lensData.ctVault && lensData.hasImplantedLensData && <Box className="d-flex justify-content-center mb-3" sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box>{labelAndInput("13")}</Box>
                                            {dropdownInput("13")}
                                        </Box>
                                        }

                                        {!lensData.vault && lensData.hasImplantedLensData &&
                                            <Box className="d-flex justify-content-center mb-3" sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="h6" style={{ fontSize: '15px' }}>
                                                    If you don't have a Vault measurement, you can provide an estimate in terms of Corneal Thickness{lensData.cct ? ` (${lensData.cct})` : ""}.
                                                </Typography>
                                            </Box>
                                        }

                                        {!lensData.vault && lensData.hasImplantedLensData &&
                                            <Box className="d-flex justify-content-center" mb={lensData.hasImplantedLensData ? 5 : 3} sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box ml={1} mr={1} >{
                                                    dropdownInput("37", { label: 'Estimated Vault', minWidth: 140, ml: 0 })}
                                                </Box>
                                            </Box>
                                        }

                                        {lensData.hasImplantedLensData &&
                                            <Box className="d-flex justify-content-center mb-3" sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box>{radioInput("38")}</Box>
                                                <Box ml={5}>{radioInput("39")}</Box>
                                            </Box>
                                        }


                                    </>)}

                            </Container>




                            <Container className="p-1">
                                <Box textAlign="center" mb={2}>
                                    <Typography variant="h5">Comprehensive Data</Typography>
                                </Box>
                                {formSection("Preoperative", "90%", 4,
                                    <>{additionalInformationSection()}</>)}
                            </Container>


                            {file && <Box textAlign="center" mb={1}>
                                <FormControlLabel
                                    control={<Checkbox checked={savePdf} onChange={(event) => setSavePdf(event.target.checked)} />}
                                    label="Encrypt and store OCOS PDF"
                                />
                            </Box>}

                            <Container className="d-flex justify-content-center p-10px mb-4" >
                                <Button variant="primary" type="submit" disabled={(isEditItem && Object.keys(lensDataUpdates).length === 0) || lensData.isEmpty}>
                                    {formSubmitButtonText}
                                </Button>
                            </Container>
                        </Box >

                        <CustomSnackbar {...snackbarProps} />

                    </ div>
                )
    );
};

export default DataUploader;