import "@aws-amplify/ui-react/styles.css";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Container } from 'react-bootstrap';

function Home() {
    const navigate = useNavigate();

    return (
        <div style={{ flex: "1", display: "flex", alignItems: "center" }}>
            <Container style={{ textAlign: "center" }}>
                <h1 className="animate__animated animate__fadeIn" style={{
                    color: "white",
                    fontSize: "3rem",
                    marginBottom: "2rem",
                    textShadow: '3px 3px 0px rgba(0,0,0,0.1)',
                }}>
                    EVO Calculator
                </h1>
                <p className="animate__animated animate__fadeIn"
                    style={{ color: "#CCCCCC", fontSize: "1.2rem", margin: "0 auto 2rem", width: "40%" }}>
                    Employing neural networks to provide precise EVO lens predictions.
                </p>

                <Button variant="success" size="lg" className="animate__animated animate__fadeIn" style={{
                    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
                    color: 'white',
                    backgroundColor: '#07BF00'
                }} onClick={() => navigate('/dashboard')}>
                    Dashboard
                </Button>
            </Container>
        </div>
    );
}

export default Home;
