import { API } from 'aws-amplify';

import User from '../models/user.js'
import Team from '../models/team.js'

async function getUserAPI() {
    var successful = false;
    var user = null;

    try {
        var response = await API.get('EVOCalculatorAPI', '/users');
        console.log(response);

        if (response.status === 200 || response.status === undefined) {
            console.log("Got from real DB")
            successful = true;

            if (Object.keys(response.user).length !== 0) {
                user = User.fromJSON({ json: response.user, gotFromDatabaseThisSession: true });
            }

            // Convert team maps to team objects and sort
            if (Object.keys(response.teams).length !== 0) {
                
                for (var index in response.teams) {
                    user.teamObjects.push(Team.fromJSON(response.teams[index]));
                }

                user.teamObjects.sort((a, b) => a.teamName.localeCompare(b.teamName));
            }
        }
    } catch (error) {
        console.log(error.response);
    }


    return [successful, user];
}

async function createUserAPI(user) {
    var successful = false;

    const payload = {
        body: { user: user.toJSONString({ preserveTeamObjects: false }) }
    };

    try {
        var response = await API.post('EVOCalculatorAPI', '/users', payload)
        console.log("Created user")
        console.log(response);
        successful = true;
    } catch (error) {
        console.log(error.response);
    }

    return successful;
}

// Takes a dictionary of updates.
async function updateUserAPI(updates) {
    var successful = false;

    // Check if dictionary
    if (updates.constructor === Object) {
        updates = JSON.stringify(updates);

        const payload = {
            body: { updates: updates }
        };

        try {
            var response = await API.put('EVOCalculatorAPI', '/users', payload)
            console.log("Updated user")
            console.log(response);
            successful = true;
        } catch (error) {
            console.log(error.response.data.message);
        }
    }
    else {
        throw new Error('Updates must be a dictionary.');
    }

    return successful;
}

export { getUserAPI, updateUserAPI, createUserAPI }