// components/Login.js
import { Auth } from 'aws-amplify';
import { useEffect, useContext, useState } from "react";
import UserContext from '../contexts/UserContext.js';
import TeamContext from '../contexts/TeamContext.js';
import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUserAPI, createUserAPI, updateUserAPI } from '../../api/UserAPI.js'
import LoadingSpinner from '../../components/LoadingSpinner';
import User from '../../models/user.js'
//import LoadingParticles from '../../components/LoadingParticles';


function Login() {
  const { user, setUser } = useContext(UserContext);
  const { team, setTeam } = useContext(TeamContext);
  const [isLoading, setLoading] = useState(false);
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';

  const CompleteLogin = (userData) => {
    if (userData.teamObjects.length > 0) {
      setTeam(userData.teamObjects[0]);
    }

    setUser(userData);
    setLoading(false);
    navigate(from, { replace: true });
  }

  useEffect(() => {
    if (route === 'authenticated') {
      if (!user.gotFromDatabaseThisSession) {
        Auth.currentAuthenticatedUser({})
          .then((authUser) => {
            setLoading(true);
            console.log("Getting from database in login")

            // Get user, if user doesn't exist, create user
            getUserAPI().then(([successful, userData]) => {
              if (userData == null) {
                if (successful) {
                  const newUser = User.fromJSON({ json: user.toJSON() });

                  newUser.uid = authUser.username;
                  newUser.email = authUser.attributes.email;

                  createUserAPI(newUser).then((successful) => {
                    if (successful) {
                      newUser.gotFromDatabaseThisSession = true;
                      CompleteLogin(newUser);
                    }
                    else {
                      // Error creating user
                      //TODO: Display error message
                    }
                  });
                }
                else {
                  // Error getting user
                }
              }
              else {
                CompleteLogin(userData);
              }
            });
          })
          .catch((err) => console.log(err));
      }
      else {
        navigate(from, { replace: true });
      }
    }
  }, [route, navigate, from]);

  return (
    <div>
      {
        isLoading ? <LoadingSpinner /> : <View className="auth-wrapper" paddingTop='75px'>
          <Authenticator></Authenticator>
        </View>
      }
    </div>
  );
}

export default Login;