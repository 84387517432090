import UserContext from '../contexts/UserContext.js';
import React, { useState, useContext, useEffect } from 'react';
import { Form, Button, Container, Dropdown } from 'react-bootstrap';
import { useNavigate, Navigate } from "react-router-dom";
import { updateUserAPI } from '../../api/UserAPI.js'
import { Auth } from 'aws-amplify';
import { Box } from '@mui/material';
import LoadingSpinner from '../../components/LoadingSpinner';
import User from '../../models/user.js'


function SignUp() {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();

    const [namePrefix, setPrefix] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [saving, setSaving] = useState(false);
    const [role, setRole] = useState('');
    const [staarSurgeonId, setStaarSurgeonId] = useState('');
    var prefixes = [...user.prefixes];
    prefixes.unshift(null);

    const handlePrefixChange = (selectedPrefix) => {
        if (selectedPrefix === null) {
            setPrefix('');
        } else {
            setPrefix(selectedPrefix);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setSaving(true);

        const newUser = User.fromJSON({ json: user.toJSON() });
        newUser.namePrefix = namePrefix;
        newUser.firstName = firstName;
        newUser.lastName = lastName;
        newUser.gotFromDatabaseThisSession = true;
        newUser.finishedSignup = true;

        // Update user in database
        Auth.currentAuthenticatedUser({})
            .then((authUser) => {
                newUser.uid = authUser.username;
                newUser.email = authUser.attributes.email;

                const userUpdates = {
                    namePrefix: newUser.namePrefix,
                    firstName: newUser.firstName,
                    lastName: newUser.lastName,
                    email: newUser.email,
                    finishedSignup: newUser.finishedSignup
                };

                updateUserAPI(userUpdates).then((successful) => {
                    if (successful) {
                        setUser(newUser);
                        navigate('/dashboard', { replace: true });
                    }
                    else {
                        //TODO: Display error message
                    }
                    setSaving(false);
                });
            })
            .catch((err) => {
                setSaving(false);
                console.log(err);
            });
    };

    return (
        <Box style={saving ? { pointerEvents: "none", opacity: "0.4" } : {}} >
            <Container className="d-flex justify-content-center">
                {saving && <LoadingSpinner inDashboard={true} />}

                <Form onSubmit={handleSubmit} className="mx-auto border rounded p-3 mt-5 bg-light">
                    <Form.Group className="mb-3" controlId="namePrefix">
                        <Form.Label>Prefix</Form.Label>
                        <Dropdown onSelect={handlePrefixChange}>
                            <Dropdown.Toggle variant="outline-secondary">
                                {namePrefix || 'Prefix'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {prefixes.map((p) => (
                                    <Dropdown.Item key={p} eventKey={p}> {p || 'None'}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="firstName">
                        <Form.Label>First Name <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="lastName">
                        <Form.Label>Last Name <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="role">
                    <Form.Label>Role*</Form.Label>
                    <Form.Check
                        type="radio"
                        id="surgeon-radio"
                        name="role"
                        value="Surgeon"
                        label="Surgeon"
                        onChange={(e) => setRole(e.target.value)}
                        required />
                    <Form.Check
                        type="radio"
                        id="staff-radio"
                        name="role"
                        value="Staff"
                        label="Staff"
                        onChange={(e) => setRole(e.target.value)} />
                </Form.Group>
                {role === 'Surgeon' && (
                    <Form.Group className="mb-3" controlId="staarSurgeonId">
                        <Form.Label>STAAR Surgeon ID</Form.Label>
                        <Form.Control
                            type="text"
                            value={staarSurgeonId}
                            onChange={(e) => setStaarSurgeonId(e.target.value)}
                        />
                    </Form.Group>
                )} */}
                    <Container className="d-flex justify-content-center">
                        <Button type="submit">Sign Up</Button>
                    </Container>
                </Form>
            </Container>
        </Box>
    );
}

export default SignUp;