import React from 'react';

function ErrorPage() {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <h3>Something went wrong</h3>
        </div>
    );
}

export default ErrorPage;
