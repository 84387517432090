import { API } from 'aws-amplify';

async function createTeamAPI(team) {
    var successful = false;
    var uid = "";

    const payload = { body: { "team": team.toJSON() } };

    console.log(payload);

    try {

        var response = await API.post('EVOCalculatorAPI', '/teams', payload)
        console.log("Created team")
        console.log(response);
        successful = true;
        uid = response.uid;
    } catch (error) {
        console.log(error.response);
    }
    return [successful, uid];
}

// Takes a dictionary of updates.
async function updateTeamAPI(updates, uid) {
    var successful = false;

    // Check if dictionary
    if (updates.constructor == Object) {
        updates["uid"] = uid;
        updates = JSON.stringify(updates);

        const payload = {
            body: { updates: updates }
        };

        try {
            var response = await API.put('EVOCalculatorAPI', '/teams', payload)
            console.log("Updated team")
            console.log(response);
            successful = true;
        } catch (error) {
            console.log(error.response.data.message);
        }
    }
    else {
        throw new Error('Updates must be a dictionary.');
    }

    return successful;
}


async function addUserToTeamAPI(email, teamUid) {
    var result = { "successful": false, "message": "" };

    var parameters = {
        "email": email,
        "teamUid": teamUid
    }

    parameters = JSON.stringify(parameters);

    const payload = {
        body: { parameters: parameters }
    };

    try {
        var res = await API.put('EVOCalculatorAPI', '/teams/addTeamMember', payload)
        console.log("Added user to team")
        console.log(res);
        result["successful"] = true;
        result["message"] = res["message"];
        result["userUid"] = res["userUid"];
    } catch (error) {
        console.log(error);
    }

    return result;
}

async function removeUserFromTeamAPI(userUid, teamUid) {
    var successful = false;

    var parameters = {
        "userUid": userUid,
        "teamUid": teamUid
    }

    const payload = {
        body: { parameters: JSON.stringify(parameters) }
    };

    try {
        var res = await API.put('EVOCalculatorAPI', '/teams/removeTeamMember', payload)
        console.log("Removed user from team")
        console.log(res);
        successful = true;
    } catch (error) {
        console.log(error);
    }

    return successful;
}

export { updateTeamAPI, createTeamAPI, addUserToTeamAPI, removeUserFromTeamAPI }