import "@aws-amplify/ui-react/styles.css";
import React, { useState, useContext, useEffect } from 'react';
import { Button, Modal } from "react-bootstrap";
import LoadingSpinner from '../../components/LoadingSpinner';
import ErrorPage from '../../components/ErrorPage';
import NoTeamPlaceHolder from '../../components/NoTeamPlaceHolder'
import CustomSnackbar, { CustomSnackbarProps } from '../../components/Snackbar';
import { addUserToTeamAPI, removeUserFromTeamAPI } from '../../api/TeamAPI.js'
import {
  TextField, Box, IconButton, Dialog, DialogTitle, DialogContent, Typography, Avatar, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText
} from '@mui/material';
import { PersonRemove, PersonAdd } from "@mui/icons-material";
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CreateTeam from './CreateTeam'
import Team from '../../models/team.js';
import TeamContext from '../contexts/TeamContext.js';
import UserContext from '../contexts/UserContext.js';


function TeamManager() {
  const { user, setUser } = useContext(UserContext);
  const { team, setTeam } = useContext(TeamContext);
  const [saving, setSaving] = useState(false)
  const [showAddTeamMember, setShowAddTeamMember] = useState(false);
  const [email, setEmail] = useState('');
  const [showAlertValues, setShowAlertValues] = useState({ show: false });
  const [dialogOpen, setDialogOpen] = useState(false);
  const isOwner = team?.members[user.uid] == "1";


  var boxStyles = {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.7)'
    },
    '& .MuiInputLabel-shrink': {
      color: 'rgba(0, 0, 0, 0.8)',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.5)',
    },
    '& .MuiInputLabel-asterisk': {
      color: '#E28B00',
      fontSize: '1rem',
      fontWeight: 'bold',
    }
  }

  const toggleDialog = (id) => {
    setDialogOpen(true);
  }

  const handleCloseAlert = (event, reason) => {
    reason === 'clickaway' || setShowAlertValues({ show: false });
  };

  const handleCloseShowAddTeamMember = () => {
    setShowAddTeamMember(false);
    setEmail('');
  }

  const addToTeam = (event) => {
    event.preventDefault();
    setSaving(true)

    addUserToTeamAPI(email, team?.uid).then((res) => {
      let successful = res["successful"];
      let message = res["message"];

      if (successful) {
        if (message === "Email does not exist") {
          setShowAlertValues({ show: true, message: "Email not associated with an account", severity: "error" });

        } else if (message === "User is already on team") {
          setShowAlertValues({ show: true, message: "User already on this team", severity: "error" });
        } else {
          const teamCopy = Team.fromJSON(team?.toJSON());

          teamCopy.members[res["userUid"]] = "2";
          teamCopy.memberEmails[res["userUid"]] = email;

          setTeam(teamCopy);

          handleCloseShowAddTeamMember();
          setShowAlertValues({ show: true, message: `Added ${email} to your team`, severity: "success" });
        }
      }
      else {
        setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });
      }

      setSaving(false);
    }).catch((err) => {
      console.log(err)
      setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });

      setSaving(false);
    });

  };

  const removeFromTeam = (userUid) => {
    setSaving(true)

    removeUserFromTeamAPI(userUid, team?.uid).then((successful) => {
      if (successful) {
        const teamCopy = Team.fromJSON(team?.toJSON());

        delete teamCopy.members[userUid];
        delete teamCopy.memberEmails[userUid];

        setTeam(teamCopy);

        setShowAlertValues({ show: true, message: `Removed ${email} from your team`, severity: "success" });
      }
      else {
        setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });
      }

      setSaving(false);
    }).catch((err) => {
      console.log(err)
      setShowAlertValues({ show: true, message: "Something went wrong", severity: "error" });

      setSaving(false);
    });
  };

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const addTeamMemberModal = () => {
    return (
      <Modal
        show={showAddTeamMember}
        onHide={() => handleCloseShowAddTeamMember()}
        style={saving ? { pointerEvents: "none", opacity: "0.8" } : {}}>
        <Modal.Header closeButton>
          <Modal.Title>Add Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Box style={saving ? { pointerEvents: "none", opacity: "0.4" } : {}}>
            <Box component="form" onSubmit={addToTeam}>
              <TextField className="d-flex" id="outlined-basic" label={"Email Address"} size="small" type="text" variant="outlined" required
                value={email} sx={{ ...boxStyles, marginTop: 1, marginBottom: 1 }} onChange={(e) => handleEmailChange(e.target.value)} />

              <Button variant="primary" type="submit">Add</Button>
            </Box>
          </Box>
        </Modal.Body>
      </Modal>
    );
  }

  const renderTeamList = () => {
    const owners = [];
    const members = [];

    // Sort memberEmails object by email
    const sortedMemberEmails = Object.entries(team?.memberEmails).sort((a, b) => {
      const emailA = a[1];
      const emailB = b[1];
      return emailA.localeCompare(emailB);
    });

    // Iterate over the sorted memberEmails object and add each member to either the
    // owners or members array, depending on their member status
    for (const [uid, email] of sortedMemberEmails) {
      const listItem = (
        <ListItem
          key={uid}
          style={{ border: "1px solid #ccc", borderRadius: "4px", marginBottom: "8px", padding: "8px" }}
        >
          <ListItemAvatar>
            <Avatar>{String(email).charAt(0).toUpperCase()}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={email}
            secondary={team?.members[uid] === "1" ? "Owner" : "Member"}
            style={{ flex: "1 1 auto", marginRight: "70px" }}
          />
          {uid !== user.uid && isOwner &&
            <ListItemSecondaryAction>
              <Button variant="contained" size="small" color="text.secondary"
                style={{ backgroundColor: 'lightgray' }} onClick={() => removeFromTeam(uid)}>
                <PersonRemove fontSize="small" />
              </Button>
            </ListItemSecondaryAction>
          }
        </ListItem>
      );

      if (team?.members[uid] === "1") {
        owners.push(listItem);
      } else {
        members.push(listItem);
      }
    }

    // Combine the owners and members arrays, with a divider between them
    const hasOwners = owners.length > 0;
    const hasMembers = members.length > 0;

    const listItems = [
      [hasOwners && <ListItem key="owners-header" style={{ fontWeight: "bold" }}>{owners.length > 1 ? "Owners" : "Owner"}</ListItem>,
      ...owners],
      [hasMembers && <ListItem key="members-header" style={{ fontWeight: "bold" }}>{members.length > 1 ? "Members" : "Member"}</ListItem>,
      ...members],
    ];

    return listItems;
  };

  const teamMembersSection = () => {
    return (
      <Box className="border rounded p-3 bg-light mb-3"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "column", width: "45%", marginTop: "20px" }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Box sx={{ display: 'flex', marginBottom: '20px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
              <Typography mb={1} ml={5} variant="h5">Team Members</Typography>
            </Box>
            <IconButton onClick={() => toggleDialog()} style={{ position: 'relative', top: '-12px', right: '-12px' }}>
              <FontAwesomeIcon icon={faCircleInfo} style={{ fontSize: '16px' }} />
            </IconButton>
          </Box>
        </Box>

        {isOwner &&
          <Box mb={2} style={{ alignItems: 'center' }}>
            <Button variant="secondary" onClick={() => setShowAddTeamMember(true)} style={{ width: '100px' }}><PersonAdd /></Button>
          </Box>
        }

        {team?.memberEmails &&
          <List>
            {renderTeamList()}
          </List>
        }

      </Box>
    );
  }

  var snackbarProps = new CustomSnackbarProps(showAlertValues.show, handleCloseAlert, showAlertValues.message, showAlertValues.severity);

  return (
    !team ? <NoTeamPlaceHolder /> :
      !team?.uid ? <ErrorPage /> :
        <Box style={saving ? { pointerEvents: "none", opacity: "0.4" } : {}} >
          {saving && <LoadingSpinner inDashboard={true} />}

          <Box sx={{ flexDirection: "column", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CreateTeam isEditTeam={true} />

            {teamMembersSection()}

            {addTeamMemberModal()}

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <DialogTitle>{"Teams"}</DialogTitle>
              <DialogContent>Team members can upload and view data linked to their team. They can also update the devices and name associated with their team.</DialogContent>
            </Dialog>

            <CustomSnackbar {...snackbarProps} />
          </Box>

        </Box >
  );

};

export default TeamManager;