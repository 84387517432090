import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navLinks } from "../../../app/App.js"
import Footer from "../components/Footer.js"

function Sidebar() {
    const location = useLocation();
    const [hovered, setHovered] = useState("");
    const handleMouseEnter = (label) => {
        setHovered(label);
    };

    const handleMouseLeave = () => {
        setHovered("");
    };

    return (
        <div
            style={{
                backgroundImage: "linear-gradient(to top, #5068E1, #7A5DC7)",
                position: 'relative',
                height: "100vh",
                width: "250px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start"
            }}>
            <div style={{
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                position: 'relative',
                height: "100vh",
                width: "250px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start"
            }}>
                <div style={{ flex: "1 0 auto" }}>
                    <h4 style={{ color: "#F1F1F1", textAlign: "center", paddingTop: "1rem", paddingBottom: "0.7rem" }}>
                        EVO Calculator
                    </h4>
                    <hr style={{ color: "#DFDFDF", width: "100%", marginTop: 0 }} />
                    <div style={{ paddingTop: "1rem" }}>
                        {navLinks.map((link) => (
                            <NavLink
                                key={link.to}
                                to={link.to}
                                style={{
                                    color: "#F1F1F1",
                                    textDecoration: "none",
                                    marginBottom: "0.6rem",
                                    fontWeight: "normal",
                                    backgroundColor:
                                        location.pathname === link.to
                                            ? "rgba(183, 183, 183, 0.5)"
                                            : hovered === link.label
                                                ? "rgba(140, 140, 140, 0.5)"
                                                : "transparent",
                                    padding: "0.7rem",
                                    borderRadius: "5px",
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "0.8rem 2.6rem",
                                    transition: "background-color 0.1s ease"
                                }}
                                onMouseEnter={() => handleMouseEnter(link.label)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <FontAwesomeIcon icon={link.icon} style={{ marginRight: "1rem" }} />
                                {link.label}
                            </NavLink>
                        ))}
                    </div>
                </div>
                <Footer />
            </div>
        </div >
    );
}

export default Sidebar;
