import { Amplify } from 'aws-amplify';
import App from './app/App';
import config from './aws-exports';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';


import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

Amplify.configure(config);

if (process.env.NODE_ENV !== "development") {
    console.log = () => { };
    console.debug = () => { };
    console.info = () => { };
    console.warn = () => { };
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
