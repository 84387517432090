import { ConsoleLogger } from '@aws-amplify/core';
import Team from '../models/team.js'

const UserRole = {
    SURGEON: 'surgeon',
    STAFF: 'staff',
};

// {
//     teamID = 1,
//     permissionLevel = 1
// }

// level 1 = owner
// level 2 = member

class User {
    constructor({ namePrefix = "", firstName = "", lastName = "",
        email = "", staarSurgeonID = "", userRole = "", teams = {},
        teamObjects = [], finishedSignup = false, gotFromDatabaseThisSession = false,
        creationTimestamp = "", lastLoginTimestamp = "", uid = "" } = {}) {
        this.namePrefix = namePrefix;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.staarSurgeonID = staarSurgeonID;
        this.userRole = userRole;
        this.teams = teams; // level 1 = owner, level 2 = member
        this.teamObjects = teamObjects;
        this.finishedSignup = finishedSignup;
        this.gotFromDatabaseThisSession = gotFromDatabaseThisSession;
        this.creationTimestamp = creationTimestamp;
        this.lastLoginTimestamp = lastLoginTimestamp;
        this.uid = uid;

        this.prefixes = ["Mr.", "Ms.", "Mrs.", "Dr.", "Prof."];
    }

    get teamObjectsMap() {
        var map = {};

        for (var key in this.teamObjects) {
            var team = this.teamObjects[key];
            map[team.uid] = team;
        }

        return map;
    }

    get teamNamesMap() {
        var map = {};

        for (var key in this.teamObjects) {
            var team = this.teamObjects[key];
            map[team.uid] = team.teamName;
        }

        return map;
    }

    get teamUids() {
        var uids = [];

        for (const [key, value] of Object.entries(this.teams)) {
            uids.push(key);
        }

        return uids;
    }

    updateTeam(newTeam) {
        const index = this.teamObjects.findIndex((team) => team.uid === newTeam.uid);

        if (index !== -1) {
            this.teamObjects[index] = newTeam;
        }
    }

    toJSONString({ preserveTeamObjects = true } = {}) {
        return JSON.stringify(this.toJSON({ preserveTeamObjects: preserveTeamObjects }));
    }

    toJSON({ preserveTeamObjects = true } = {}) {
        var json = {
            namePrefix: this.namePrefix,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            staarSurgeonID: this.staarSurgeonID,
            userRole: this.userRole,
            teams: this.teams,
            finishedSignup: this.finishedSignup,
            creationTimestamp: this.creationTimestamp,
            lastLoginTimestamp: this.lastLoginTimestamp,
            uid: this.uid
        };

        if (preserveTeamObjects) {
            json["teamObjects"] = this.teamObjects;
        }

        return json;
    }

    static fromJSON({ json = {}, fromDatabase = false } = {}) {
        return new User({
            namePrefix: json.namePrefix ?? "",
            firstName: json.firstName ?? "",
            lastName: json.lastName ?? "",
            email: json.email ?? "",
            staarSurgeonID: json.staarSurgeonID ?? "",
            userRole: json.userRole || UserRole.STAFF,
            teams: json.teams || {},
            teamObjects: json.teamObjects || [],
            finishedSignup: json.finishedSignup || false,
            gotFromDatabaseThisSession: fromDatabase || false,
            creationTimestamp: json.creationTimestamp || new Date().toISOString(),
            lastLoginTimestamp: json.lastLoginTimestamp || new Date().toISOString(),
            uid: json.uid ?? ""
        });
    }
}

export { UserRole };
export default User;