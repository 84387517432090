class Team {
    constructor({ officeStreet = "", officeCity = "", officeState = "",
        officeZipCode = "", teamName = "", members = {}, memberEmails = {}, emailInvitations = [],
        creationTimestamp = "", vaultDeviceId = null, kDeviceId = null, acdDeviceId = null,
        cctDeviceId = null, wtwDeviceId = null, axialLenDeviceId = null, ltDeviceId = null,
        stsDeviceId = null, stslDeviceId = null, ataDeviceId = null,
        clrDeviceId = null, acwDeviceId = null, cbidDeviceId = null,
        spdDeviceId = null, irisDiamDeviceId = null, cornealRadDeviceId = null,
        aod500DeviceId = null, tia500DeviceId = null, cvDeviceId = null, uid = "" } = {}) {

        this.officeStreet = officeStreet;
        this.officeCity = officeCity;
        this.officeState = officeState;
        this.officeZipCode = officeZipCode;
        this.teamName = teamName;
        this.members = members; // level 1 = owner, level 2 = member
        this.memberEmails = memberEmails;
        this.emailInvitations = emailInvitations;
        this.creationTimestamp = creationTimestamp;

        // Device IDs
        this.vaultDeviceId = vaultDeviceId;
        this.kDeviceId = kDeviceId;
        this.acdDeviceId = acdDeviceId;
        this.cctDeviceId = cctDeviceId;
        this.wtwDeviceId = wtwDeviceId;
        this.axialLenDeviceId = axialLenDeviceId;
        this.ltDeviceId = ltDeviceId;
        this.stsDeviceId = stsDeviceId;
        this.stslDeviceId = stslDeviceId;
        this.ataDeviceId = ataDeviceId;
        this.clrDeviceId = clrDeviceId;
        this.acwDeviceId = acwDeviceId;
        this.cbidDeviceId = cbidDeviceId;
        this.spdDeviceId = spdDeviceId;
        this.irisDiamDeviceId = irisDiamDeviceId;
        this.cornealRadDeviceId = cornealRadDeviceId;
        this.aod500DeviceId = aod500DeviceId;
        this.tia500DeviceId = tia500DeviceId;
        this.cvDeviceId = cvDeviceId;

        this.uid = uid;
    }

    get deviceIds() {
        return {
            vaultDeviceId: this.vaultDeviceId ?? null,
            kDeviceId: this.kDeviceId ?? null,
            acdDeviceId: this.acdDeviceId ?? null,
            cctDeviceId: this.cctDeviceId ?? null,
            wtwDeviceId: this.wtwDeviceId ?? null,
            axialLenDeviceId: this.axialLenDeviceId ?? null,
            ltDeviceId: this.ltDeviceId ?? null,
            stsDeviceId: this.stsDeviceId ?? null,
            stslDeviceId: this.stslDeviceId ?? null,
            ataDeviceId: this.ataDeviceId ?? null,
            clrDeviceId: this.clrDeviceId ?? null,
            acwDeviceId: this.acwDeviceId ?? null,
            cbidDeviceId: this.cbidDeviceId ?? null,
            spdDeviceId: this.spdDeviceId ?? null,
            irisDiamDeviceId: this.irisDiamDeviceId ?? null,
            cornealRadDeviceId: this.cornealRadDeviceId ?? null,
            aod500DeviceId: this.aod500DeviceId ?? null,
            tia500DeviceId: this.tia500DeviceId ?? null,
            cvDeviceId: this.cvDeviceId ?? null
        };
    }

    toJSONString() {
        return JSON.stringify(this.toJSON());
    }

    toJSON() {
        return {
            officeStreet: this.officeStreet,
            officeCity: this.officeCity,
            officeState: this.officeState,
            officeZipCode: this.officeZipCode,
            teamName: this.teamName,
            members: this.members,
            memberEmails: this.memberEmails,
            emailInvitations: this.emailInvitations,
            creationTimestamp: this.creationTimestamp,

            vaultDeviceId: this.vaultDeviceId,
            kDeviceId: this.kDeviceId,
            acdDeviceId: this.acdDeviceId,
            cctDeviceId: this.cctDeviceId,
            wtwDeviceId: this.wtwDeviceId,
            axialLenDeviceId: this.axialLenDeviceId,
            ltDeviceId: this.ltDeviceId,
            stsDeviceId: this.stsDeviceId,
            stslDeviceId: this.stslDeviceId,
            ataDeviceId: this.ataDeviceId,
            clrDeviceId: this.clrDeviceId,
            acwDeviceId: this.acwDeviceId,
            cbidDeviceId: this.cbidDeviceId,
            spdDeviceId: this.spdDeviceId,
            irisDiamDeviceId: this.irisDiamDeviceId,
            cornealRadDeviceId: this.cornealRadDeviceId,
            aod500DeviceId: this.aod500DeviceId,
            tia500DeviceId: this.tia500DeviceId,
            cvDeviceId: this.cvDeviceId,

            uid: this.uid
        };
    }

    static fromJSON(json) {
        return new Team({
            officeStreet: json.officeStreet ?? "",
            officeCity: json.officeCity ?? "",
            officeState: json.officeState ?? "",
            officeZipCode: json.officeZipCode ?? "",
            teamName: json.teamName ?? "",
            members: json.members || {},
            memberEmails: json.memberEmails || {},
            emailInvitations: json.emailInvitations || [],
            creationTimestamp: json.creationTimestamp || new Date().toISOString(),

            vaultDeviceId: json.vaultDeviceId || null,
            kDeviceId: json.kDeviceId || null,
            acdDeviceId: json.acdDeviceId || null,
            cctDeviceId: json.cctDeviceId || null,
            wtwDeviceId: json.wtwDeviceId || null,
            axialLenDeviceId: json.axialLenDeviceId || null,
            ltDeviceId: json.ltDeviceId || null,
            stsDeviceId: json.stsDeviceId || null,
            stslDeviceId: json.stslDeviceId || null,
            ataDeviceId: json.ataDeviceId || null,
            clrDeviceId: json.clrDeviceId || null,
            acwDeviceId: json.acwDeviceId || null,
            cbidDeviceId: json.cbidDeviceId || null,
            spdDeviceId: json.spdDeviceId || null,
            irisDiamDeviceId: json.irisDiamDeviceId || null,
            cornealRadDeviceId: json.cornealRadDeviceId || null,
            aod500DeviceId: json.aod500DeviceId || null,
            tia500DeviceId: json.tia500DeviceId || null,
            cvDeviceId: json.cvDeviceId || null,

            uid: json.uid ?? ""
        });
    }
}

export default Team;