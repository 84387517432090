import UserContext from '../contexts/UserContext.js';
import { useLocation, Navigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

function RequireSignUp({ children, isToSignup }) {
    const { user, setUser } = useContext(UserContext);
    const location = useLocation();
    const { route } = useAuthenticator((context) => [context.route]);

    if (isToSignup === undefined) {
        isToSignup = false;
    }

    // Always navigate to login if user is unauthenticated or their user object has not been pulled.
    if (route !== 'authenticated' || !user.uid) {
        return <Navigate to="/login" state={{ from: location }} replace={true} />;
    }

    // If the user is navigating to the signup page but has completed signup, go to dashboard.
    if (isToSignup && user.finishedSignup) {
        return <Navigate to="/dashboard" state={{ from: location }} replace={true} />;
    }

    // If the user is going to a page other than signup but has not finished signup, go to signup.
    if (!isToSignup && !user.finishedSignup && user.uid !== "") {
        return <Navigate to="/signUp" state={{ from: location }} replace={true} />;
    }

    // Otherwise, go to where user is navigating. 

    // This handles when the user is going to signup and has not completed signup.
    // Doing this with Navigate will cause an infinite loop of navigating to signup.

    return children;
}

export default RequireSignUp;