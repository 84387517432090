import React from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

function NoTeamPlaceHolder() {
    let navigate = useNavigate();

    const navigateCreateTeam = () => {
        navigate('/dashboard/create-team')
    };

    return (
        <div className="border rounded p-3 mb-3 mt-2 bg-light" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h4 style={{ marginBottom: '20px', marginTop: '30px' }}>Create a team or ask a team owner to invite you.</h4>
            <Button variant="secondary" className="btn btn-primary px-5 py-3 rounded-pill" style={{ width: '20%', marginBottom: "20px" }} onClick={navigateCreateTeam} >
                <FontAwesomeIcon icon={faAdd} style={{ marginRight: "1rem" }} />
                Create Team
            </Button>
        </div>
    );
}

export default NoTeamPlaceHolder;
