import dayjs from 'dayjs';

class LensData {
    constructor({ vault = "", ctVault = null, bvd = "", sphere = "", cylinder = "",
        axis = "", k1 = "", k1Axis = "", k2 = "",
        k2Axis = "", trueACD = "", cct = "", wtw = "", clSphere = "",
        axialLen = "", lt = "", sts = "", stsl = "", ata = "",
        clr = "", acw = "", cbid = "", spd = "", irisDiam = "",
        cornealRad = "", aod500 = "", tia500 = "", cv = "",
        longerIsAcceptable = null, shorterIsAcceptable = null,
        vaultDeviceId = null, kDeviceId = null, acdDeviceId = null, cctDeviceId = null,
        wtwDeviceId = null, axialLenDeviceId = null, ltDeviceId = null,
        stsDeviceId = null, stslDeviceId = null, ataDeviceId = null,
        clrDeviceId = null, acwDeviceId = null, cbidDeviceId = null,
        spdDeviceId = null, irisDiamDeviceId = null, cornealRadDeviceId = null,
        aod500DeviceId = null, tia500DeviceId = null, cvDeviceId = null,
        implantedSize = null, implantedSphere = "", implantedCylinder = null, implantedAxis = "",
        targetName = "", targetSize = "", targetSphere = "", targetCylinder = "",
        targetAxis = "", expectedSphere = "", expectedCylinder = "",
        expectedAxis = "", expectedSeq = "", eye = null, sex = null, ethnicity = null,
        patientId = "", patientDob = null, teamUid = "", uploaderUid = "", surgeonId = "",
        surgeonName = "", ocosVersion = "", successfullyParsed = "", creationTimestamp = "",
        hasPdf = false, uid = "" } = {}) {

        // OCOS data
        this.bvd = bvd;
        this.sphere = sphere; // - with 2 decimals
        this.cylinder = cylinder; // two decimals
        this.axis = axis; // integer
        this.k1 = k1; // Two decimals
        this.k1Axis = k1Axis; // integer
        this.k2 = k2;  // Two decimals
        this.k2Axis = k2Axis; // integer
        this.trueACD = trueACD;
        this.cct = cct;
        this.wtw = wtw;
        this.clSphere = clSphere;

        // Important add ons
        this.axialLen = axialLen;
        this.lt = lt;
        this.sts = sts;
        this.stsl = stsl;
        this.ata = ata;
        this.clr = clr;
        this.acw = acw;
        this.cbid = cbid;

        // Rare measurements
        this.spd = spd;
        this.irisDiam = irisDiam;
        this.cornealRad = cornealRad;
        this.aod500 = aod500;
        this.tia500 = tia500;
        this.cv = cv;

        // Device ids
        this.vaultDeviceId = vaultDeviceId;
        this.kDeviceId = kDeviceId;
        this.acdDeviceId = acdDeviceId;
        this.cctDeviceId = cctDeviceId;
        this.wtwDeviceId = wtwDeviceId;
        this.axialLenDeviceId = axialLenDeviceId;
        this.ltDeviceId = ltDeviceId;
        this.stsDeviceId = stsDeviceId;
        this.stslDeviceId = stslDeviceId;
        this.ataDeviceId = ataDeviceId;
        this.clrDeviceId = clrDeviceId;
        this.acwDeviceId = acwDeviceId;
        this.cbidDeviceId = cbidDeviceId;
        this.spdDeviceId = spdDeviceId;
        this.irisDiamDeviceId = irisDiamDeviceId;
        this.cornealRadDeviceId = cornealRadDeviceId;
        this.aod500DeviceId = aod500DeviceId;
        this.tia500DeviceId = tia500DeviceId;
        this.cvDeviceId = cvDeviceId;

        // Postoperative measurements
        this.vault = vault;
        this.ctVault = ctVault;
        this.longerIsAcceptable = longerIsAcceptable;
        this.shorterIsAcceptable = shorterIsAcceptable;
        this.implantedSize = implantedSize;
        this.implantedSphere = implantedSphere;
        this.implantedCylinder = implantedCylinder;
        this.implantedAxis = implantedAxis;

        // OCOS predicted measurements
        this.targetName = targetName;
        this.targetSize = targetSize;
        this.targetSphere = targetSphere;
        this.targetCylinder = targetCylinder;
        this.targetAxis = targetAxis;

        this.expectedSphere = expectedSphere;
        this.expectedCylinder = expectedCylinder;
        this.expectedAxis = expectedAxis;
        this.expectedSeq = expectedSeq;

        // Patient information
        this.eye = eye;
        this.sex = sex;
        this.ethnicity = ethnicity;
        this.patientId = patientId;
        this.patientDob = patientDob;

        this.teamUid = teamUid;
        this.uploaderUid = uploaderUid;
        this.surgeonId = surgeonId;
        this.surgeonName = surgeonName;

        this.ocosVersion = ocosVersion;
        this.successfullyParsed = successfullyParsed;
        this.creationTimestamp = creationTimestamp;
        this.hasPdf = hasPdf;
        this.uid = uid;

        this.overwriteableValueLabels = []


        // Sphere are -

        // Myopic only has sphere

        this.idsInOcosPDF = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "14", "15"];
        this.additionalDataIds = ["21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34"];

        this.measurementIdToAttribute = {
            "1": "bvd",
            "2": "sphere",
            "3": "cylinder",
            "4": "axis",
            "5": "k1",
            "6": "k1Axis",
            "7": "k2",
            "8": "k2Axis",
            "9": "trueACD",
            "10": "cct",
            "11": "wtw",
            "12": "clSphere",
            "13": "vault",
            "37": "ctVault",
            "14": "patientId",
            "15": "patientDob",
            "16": "eye",
            "17": "implantedSize",
            "18": "implantedSphere",
            "19": "implantedCylinder",
            "20": "implantedAxis",
            "21": "axialLen",
            "22": "lt",
            "23": "sts",
            "24": "stsl",
            "25": "ata",
            "26": "clr",
            "27": "acw",
            "28": "cbid",
            "29": "spd",
            "30": "irisDiam",
            "31": "cornealRad",
            "32": "aod500",
            "33": "tia500",
            "34": "cv",
            "35": "sex",
            "36": "ethnicity",
            "38": "longerIsAcceptable",
            "39": "shorterIsAcceptable"
        }

        // Device attributes must include "Id", as used in deviceIdAttribute of data uploader.
        this.measurementIdToDeviceIdAttribute = {
            "5": "kDeviceId",
            "6": "kDeviceId",
            "7": "kDeviceId",
            "8": "kDeviceId",
            "9": "acdDeviceId",
            "10": "cctDeviceId",
            "11": "wtwDeviceId",
            "13": "vaultDeviceId",
            "37": "ctVault",
            "16": "eye",
            "17": "implantedSize",
            "19": "implantedCylinder",
            "21": "axialLenDeviceId",
            "22": "ltDeviceId",
            "23": "stsDeviceId",
            "24": "stslDeviceId",
            "25": "ataDeviceId",
            "26": "clrDeviceId",
            "27": "acwDeviceId",
            "28": "cbidDeviceId",
            "29": "spdDeviceId",
            "30": "irisDiamDeviceId",
            "31": "cornealRadDeviceId",
            "32": "aod500DeviceId",
            "33": "tia500DeviceId",
            "34": "cvDeviceId",
            "35": "sex",
            "36": "ethnicity"
        }

        this.measurementIdToLabel = {
            "1": "BVD",
            "2": "Sphere",
            "3": "Cyl",
            "4": "Axis",
            "5": "K1",
            "6": "Axis",
            "7": "K2",
            "8": "Axis",
            "9": "tACD",
            "10": "CT",
            "11": "WW",
            "12": "CLSph",
            "13": "Vault",
            "37": "Estimated Vault",
            "14": "Patient ID",
            "15": "Patient DOB",
            "16": "Eye",
            "17": "Size",
            "18": "Sphere",
            "19": "Cylinder",
            "20": "Axis",
            "21": "Axial Len",
            "22": "Lens Thk",
            "23": "STS",
            "24": "STSL",
            "25": "ATA",
            "26": "CLR",
            "27": "ACW",
            "28": "CBID",
            "29": "SPD",
            "30": "IrisDiam",
            "31": "CornRad",
            "32": "AOD 500",
            "33": "TIA 500",
            "34": "Corn Vol",
            "35": "Sex",
            "36": "Ethnicity"
        }

        this.measurementIdToDeviceId = {
            "5": ["2"],
            "6": ["2"],
            "7": ["2"],
            "8": ["2"],
            "9": ["1", "2", "3"],
            "10": ["1", "2", "3"],
            "11": ["2", "4", "5"],
            "13": ["1", "3"],
            "37": ["11"],
            "16": ["6"],
            "17": ["7"],
            "19": ["8"],
            "21": ["2"],
            "22": ["1", "2", "3"],
            "23": ["1", "3"],
            "24": ["1", "3"],
            "25": ["1", "3"],
            "26": ["1", "3"],
            "27": ["1", "3"],
            "28": ["1", "3"],
            "29": ["1", "2", "3", "4"],
            "30": ["1", "2", "3", "4"],
            "31": ["4"],
            "32": ["3"],
            "33": ["3"],
            "34": ["3"],
            "35": ["9"],
            "36": ["10"]
        }

        this.measurementIdToTip = {
            "1": "Base Vertex Distance",
            "2": "Sphere",
            "3": "Cylinder",
            "4": "Axis",
            "5": "K1",
            "6": "K1 Axis",
            "7": "K2",
            "8": "K2 Axis",
            "9": "True Anterior Chamber Depth AQD (Aqueous Depth)",
            "10": "Corneal Thickness",
            "11": "White to White",
            "12": "CL Sphere",
            "13": "Implanted Lens Vault",
            "37": "Vault Estimated as a Multiple of Corneal Thickness",
            "14": "Patient ID",
            "15": "Patient Date of Birth",
            "16": "Eye",
            "17": "Implanted Lens Size",
            "18": "Implanted Lens Sphere",
            "19": "Implanted Lens Cylinder",
            "20": "Implanted Lens Axis",
            "21": "Axial Length",
            "22": "Lens Thickness",
            "23": "Sulcus to Sulcus",
            "24": "Sulcus to Sulcus Lens Rise",
            "25": "Angle to Angle (Anterior Chamber Angle Distance)",
            "26": "Clear Lens Rise",
            "27": "Anterior Chamber Width",
            "28": "Ciliary Body Internal Diameter",
            "29": "Scotoptic Pupil Diameter",
            "30": "Iris Diameter",
            "31": "Corneal Radius",
            "32": "Anterior Opening Distance at 500 Microns",
            "33": "Trabecular Iris Angle at 500 Microns",
            "34": "Corneal Volume",
            "35": "Sex",
            "36": "Ethnicity",
        }

        this.measurementIdToDeviceLabel = {
            "5": "Keratometry Device",
            "9": "True ACD Device",
            "10": "Corneal Thickness Device",
            "11": "White to White Device",
            "21": "Axial Length Device",
            "22": "Lens Thickness Device",
            "23": "Sulcus to Sulcus Device",
            "24": "STS Lens Rise Device",
            "25": "Angle to Angle Device",
            "26": "Clear Lens Rise Device",
            "27": "Anterior Cham Width Device",
            "29": "Scotoptic Pupil Diam Device",
            "30": "Iris Diameter Device",
            "31": "Corneal Radius Device",
            "34": "Corneal Volume Device"
        }

        this.radioTitles = {
            "38": "A longer EVO length would have been clinically acceptable.",
            "39": "A shorter EVO length would have been clinically acceptable."
        }

        this.radioOptions = {
            "38": [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }],
            "39": [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]
        }

        this.requiredDropdownExceptions = ["16", "35", "36", "17", "19"];

        this.deviceIdToDevicesMap = {
            // UBM
            "1":
            {
                "101": "Insight 100 (ArcScan)",
                "102": "Vu-Max-II (Sonomed-Escalon)",
                "103": "ABS (Quantel)",
                "104": "Model 835 (Zeiss)",
                "105": "Scanmate (DGH)",
                "106": "UD-800 (Tomey)",
                "107": "Reflex (Reichert Technologies)",
                "108": "UBM Plus (Keeler USA)",
                "109": "HiScan (Optikon)",
                "110": "MD-300L (MEDA)",
                "111": "SW-3200L (Super Tianjin)"
            }
            ,
            // BIOM
            "2":
            {
                "201": "IOLMaster 700 (Zeiss)",
                "202": "Lenstar LS 900 (HS)",
                "203": "AL-Scan (Nidek)",
                "204": "OA-2000 (Tomey)",
                "205": "Argus (Alcon)",
                "206": "Pentacam (Oculus)"
            }
            ,
            // OCT
            "3":
            {
                "301": "Casia2 (Tomey)",
                "302": "Cirrus 6000 (Zeiss)",
                "303": "Visante (Zeiss)",
                "304": "RS-3000 (Nidek)",
                "305": "Anterion (Heidelberg)",
                "306": "Avanti (OptoVue)",
                "307": "MS-39 (CSO)"
            }
            ,
            // Topographer
            "4":
            {
                "401": "Pentacam (Oculus)",
                "402": "Keratograph (Oculus)",
                "403": "OPD-3 (Nidek)",
                "404": "TMS-4N (Tomey)",
                "405": "RT-7000 (Tomey)",
                "406": "Atlas 9000 (Zeiss)",
                "407": "Galilei (Ziemer)"
            },
            // White to White addition
            "5":
            {
                "501": "Manual Caliper",
                "502": "Digital Caliper",
            },

            // These are for dropdowns that don't involve devices

            // Eye
            "6":
            {
                "OD": "OD",
                "OS": "OS"
            },
            // Lens size
            "7":
            {
                "12.1": "12.1",
                "12.6": "12.6",
                "13.2": "13.2",
                "13.7": "13.7"
            },
            // Cylinder +: 0 (default), 1.0, 1.5, 2, 2.5, 3, 3.5, 4
            "8":
            {
                "1.0": "1.0",
                "1.5": "1.5",
                "2.0": "2.0",
                "2.5": "2.5",
                "3.0": "3.0",
                "3.5": "3.5",
                "4.0": "4.0"
            },
            // Sex
            "9":
            {
                "Male": "Male",
                "Female": "Female"
            },
            // Ethnicity
            "10":
            {
                "601": "White",
                "602": "Asian",
                "603": "Black or African American",
                "604": "Native Hawaiian or Other Pacific Islander",
                "605": "American Indian or Alaskan Native",
                "606": "Middle Eastern or North African",
                "607": "Hispanic or Latino"
            },
            // Vault in terms of corneal thickness
            "11":
            {
                "0.1 CT": "0.1 CT",
                "0.2 CT": "0.2 CT",
                "0.3 CT": "0.3 CT",
                "0.4 CT": "0.4 CT",
                "0.5 CT": "0.5 CT",
                "0.6 CT": "0.6 CT",
                "0.7 CT": "0.7 CT",
                "0.8 CT": "0.8 CT",
                "0.9 CT": "0.9 CT",
                "1.0 CT": "1.0 CT",
                "1.1 CT": "1.1 CT",
                "1.2 CT": "1.2 CT",
                "1.3 CT": "1.3 CT",
                "1.4 CT": "1.4 CT",
            }
        }

        this.errorCriteria = new Map([
            [["4", "6", "8", "20"], { min: 0, max: 180, integer: true }],
            [["2", "18"], { min: -20, max: -0.25 }],
            [["5", "7"], { min: 30, max: 60 }],
            [["9"], { min: 3, max: 4.5 }],
            [["10"], { min: 0.3, max: 0.9 }],
            [["11"], { min: 10.5, max: 12.9 }],
            [["1"], { min: 4, max: 17 }],
            [["3"], { min: -4, max: 4 }],
            [["12"], { min: -20, max: 0 }], // Is CL Sphere right?
            [["13"], { min: 0, max: 2000, integer: true }],
            [["23", "25", "27", "28"], { min: 10, max: 14 }],
            [["24", "26"], { min: 0, max: 2 }],
            [["32", "33"], { min: 10, max: 90 }],
            [["21"], { min: 15, max: 35 }],
            [["22"], { min: 3, max: 6 }],
            [["29"], { min: 3, max: 10 }],
            [["30"], { min: 3, max: 12 }],
            [["31"], { min: 6, max: 9 }],
            [["34"], { min: 0 }]
        ]);
    }

    validate(id) {
        var isValid = false;
        var attribute = this.measurementIdToAttribute[id];
        var value = this[attribute];
        var broke = false;

        // If is date entry
        if (id === "15") {
            isValid = dayjs(value).isValid();
        }
        else if ((value || value === 0)) {
            const valueAsNum = Number(value);

            for (const keyList of this.errorCriteria.keys()) {
                if (keyList.includes(id)) {
                    const { min, max, integer } = this.errorCriteria.get(keyList);

                    if (!isNaN(valueAsNum) &&
                        valueAsNum >= min &&
                        (max === undefined || valueAsNum <= max) &&
                        (integer === undefined || Number.isInteger(valueAsNum))) {
                        isValid = true;
                    }

                    broke = true;
                    break;
                }
            }

            // For things we don't error check, say they are valid
            if (!broke) {
                isValid = true;
            }
        }

        return isValid;
    }

    getErrorMessageFromId(id) {
        var errorMessage = "";

        // If is date entry
        if (id == "15") {
            errorMessage = "Invalid date";
        }
        else {
            for (const keyList of this.errorCriteria.keys()) {
                if (keyList.includes(id)) {
                    const { min, max, integer } = this.errorCriteria.get(keyList);

                    if (max === undefined) {
                        errorMessage = `>= ${min}`
                    }
                    else {
                        errorMessage = `${min} - ${max}`
                    }

                    break;
                }
            }
        }

        return errorMessage;
    }

    clearPostOpDataIfNoLens() {
        if (!this.hasImplantedLensData) {
            this.vault = "";
            this.ctVault = null;
            this.longerIsAcceptable = null;
            this.shorterIsAcceptable = null;
        }
    }

    get hasImplantedLensData() {
        return !!this.implantedSize;
    }

    get patientDobtoString() {
        const date = dayjs(this.patientDob);
        return date.isValid() ? date.format('MM/DD/YYYY') : '';
    }

    get allDevices() {
        var all = {};

        for (const [key, value] of Object.entries(this.deviceIdToDevicesMap)) {
            all = { ...all, ...value };
        }

        return all;
    }

    get isEmpty() {
        var isEmpty = true;

        for (const [key, value] of Object.entries(this.measurementIdToAttribute)) {
            if (this[value]) {
                isEmpty = false;
                break;
            }
        }

        return isEmpty;
    }

    get getOverwriteableValues() {
        this.overwriteableValueLabels = [];

        for (const id of this.idsInOcosPDF) {
            const attribute = this.measurementIdToAttribute[id];

            if (this[attribute]) {
                this.overwriteableValueLabels.push(this.measurementIdToTip[id]);
            }
        }

        return this.overwriteableValueLabels;
    }

    get overwriteableValuesToString() {
        return this.overwriteableValueLabels.map(label => "- " + label).join("\n");
    }

    attributeToId(attribute) {
        return Object.keys(this.measurementIdToAttribute).find(key => this.measurementIdToAttribute[key] === attribute);
    }

    deviceNameToId(name) {
        return Object.keys(this.allDevices).find(key => this.allDevices[key] === name);
    }

    deviceIdToName(id) {
        return this.allDevices[id];
    }

    deviceNamesForId(id) {
        var deviceNames = []

        for (var mId of this.measurementIdToDeviceId[id] || []) {
            deviceNames = deviceNames.concat(Object.values(this.deviceIdToDevicesMap[mId]));
        }

        deviceNames.sort((a, b) => a.localeCompare(b));
        deviceNames = [...new Set(deviceNames)]

        return deviceNames
    }

    measurementNameFromId(id) {
        return this.measurementIdToLabel[id];
    }

    get measurementIds() {
        return Object.keys(this.measurementIdToLabel);
    }

    measurementFromId(id) {
        return this[this.measurementIdToAttribute[id]];
    }

    deviceFromId(id) {
        return this[this.measurementIdToDeviceIdAttribute[id]];
    }

    getRadioTitleFromId(id) {
        return this.radioTitles[id];
    }

    getRadioOptionsFromId(id) {
        return this.radioOptions[id];
    }

    toJSON() {
        return {
            bvd: this.bvd,
            sphere: this.sphere,
            cylinder: this.cylinder,
            axis: this.axis,
            k1: this.k1,
            k1Axis: this.k1Axis,
            k2: this.k2,
            k2Axis: this.k2Axis,
            trueACD: this.trueACD,
            cct: this.cct,
            wtw: this.wtw,
            clSphere: this.clSphere,

            axialLen: this.axialLen,
            lt: this.lt,
            sts: this.sts,
            stsl: this.stsl,
            ata: this.ata,
            clr: this.clr,
            acw: this.acw,
            cbid: this.cbid,

            spd: this.spd,
            irisDiam: this.irisDiam,
            cornealRad: this.cornealRad,
            aod500: this.aod500,
            tia500: this.tia500,
            cv: this.cv,

            vaultDeviceId: this.vaultDeviceId,
            kDeviceId: this.kDeviceId,
            acdDeviceId: this.acdDeviceId,
            cctDeviceId: this.cctDeviceId,
            wtwDeviceId: this.wtwDeviceId,
            axialLenDeviceId: this.axialLenDeviceId,
            ltDeviceId: this.ltDeviceId,
            stsDeviceId: this.stsDeviceId,
            stslDeviceId: this.stslDeviceId,
            ataDeviceId: this.ataDeviceId,
            clrDeviceId: this.clrDeviceId,
            acwDeviceId: this.acwDeviceId,
            cbidDeviceId: this.cbidDeviceId,
            spdDeviceId: this.spdDeviceId,
            irisDiamDeviceId: this.irisDiamDeviceId,
            cornealRadDeviceId: this.cornealRadDeviceId,
            aod500DeviceId: this.aod500DeviceId,
            tia500DeviceId: this.tia500DeviceId,
            cvDeviceId: this.cvDeviceId,

            vault: this.vault,
            ctVault: this.ctVault,
            longerIsAcceptable: this.longerIsAcceptable,
            shorterIsAcceptable: this.shorterIsAcceptable,
            implantedSize: this.implantedSize,
            implantedSphere: this.implantedSphere,
            implantedCylinder: this.implantedCylinder,
            implantedAxis: this.implantedAxis,

            targetName: this.targetName,
            targetSize: this.targetSize,
            targetSphere: this.targetSphere,
            targetCylinder: this.targetCylinder,
            targetAxis: this.targetAxis,

            expectedSphere: this.expectedSphere,
            expectedCylinder: this.expectedCylinder,
            expectedAxis: this.expectedAxis,
            expectedSeq: this.expectedSeq,

            eye: this.eye,
            sex: this.sex,
            ethnicity: this.ethnicity,
            patientId: this.patientId,
            patientDob: this.patientDob,

            teamUid: this.teamUid,
            uploaderUid: this.uploaderUid,
            surgeonId: this.surgeonId,
            surgeonName: this.surgeonName,

            ocosVersion: this.ocosVersion,
            successfullyParsed: this.successfullyParsed,
            creationTimestamp: this.creationTimestamp,
            hasPdf: this.hasPdf,
            uid: this.uid
        };
    }

    toJSONString() {
        return JSON.stringify(this.toJSON());
    }

    static fromJSON(json) {
        return new LensData({
            bvd: json.bvd ?? "",
            sphere: json.sphere ?? "",
            cylinder: json.cylinder ?? "",
            axis: json.axis ?? "",
            k1: json.k1 ?? "",
            k1Axis: json.k1Axis ?? "",
            k2: json.k2 ?? "",
            k2Axis: json.k2Axis ?? "",
            trueACD: json.trueACD ?? "",
            cct: json.cct ?? "",
            wtw: json.wtw ?? "",
            clSphere: json.clSphere ?? "",

            axialLen: json.axialLen ?? "",
            lt: json.lt ?? "",
            sts: json.sts ?? "",
            stsl: json.stsl ?? "",
            ata: json.ata ?? "",
            clr: json.clr ?? "",
            acw: json.acw ?? "",
            cbid: json.cbid ?? "",

            spd: json.spd ?? "",
            irisDiam: json.irisDiam ?? "",
            cornealRad: json.cornealRad ?? "",
            aod500: json.aod500 ?? "",
            tia500: json.tia500 ?? "",
            cv: json.cv ?? "",

            vaultDeviceId: json.vaultDeviceId || null,
            kDeviceId: json.kDeviceId || null,
            acdDeviceId: json.acdDeviceId || null,
            cctDeviceId: json.cctDeviceId || null,
            wtwDeviceId: json.wtwDeviceId || null,
            axialLenDeviceId: json.axialLenDeviceId || null,
            ltDeviceId: json.ltDeviceId || null,
            stsDeviceId: json.stsDeviceId || null,
            stslDeviceId: json.stslDeviceId || null,
            ataDeviceId: json.ataDeviceId || null,
            clrDeviceId: json.clrDeviceId || null,
            acwDeviceId: json.acwDeviceId || null,
            cbidDeviceId: json.cbidDeviceId || null,
            spdDeviceId: json.spdDeviceId || null,
            irisDiamDeviceId: json.irisDiamDeviceId || null,
            cornealRadDeviceId: json.cornealRadDeviceId || null,
            aod500DeviceId: json.aod500DeviceId || null,
            tia500DeviceId: json.tia500DeviceId || null,
            cvDeviceId: json.cvDeviceId || null,

            vault: json.vault ?? "",
            ctVault: json.ctVault || null,
            longerIsAcceptable: json.longerIsAcceptable || null,
            shorterIsAcceptable: json.shorterIsAcceptable || null,
            implantedSize: json.implantedSize || null,
            implantedSphere: json.implantedSphere ?? "",
            implantedCylinder: json.implantedCylinder || null,
            implantedAxis: json.implantedAxis ?? "",

            targetName: json.targetName ?? "",
            targetSize: json.targetSize ?? "",
            targetSphere: json.targetSphere ?? "",
            targetCylinder: json.targetCylinder ?? "",
            targetAxis: json.targetAxis ?? "",

            expectedSphere: json.expectedSphere ?? "",
            expectedCylinder: json.expectedCylinder ?? "",
            expectedAxis: json.expectedAxis ?? "",
            expectedSeq: json.expectedSeq ?? "",

            eye: json.eye || null,
            sex: json.sex || null,
            ethnicity: json.ethnicity || null,
            patientId: json.patientId ?? "",
            patientDob: json.patientDob || null,

            teamUid: json.teamUid ?? "",
            uploaderUid: json.uploaderUid ?? "",
            surgeonId: json.surgeonId ?? "",
            surgeonName: json.surgeonName ?? "",

            ocosVersion: json.ocosVersion ?? "",
            successfullyParsed: json.successfullyParsed ?? "",
            creationTimestamp: json.creationTimestamp ?? "",
            hasPdf: json.hasPdf ?? "",
            uid: json.uid ?? ""
        });
    }
}

export default LensData;