// components/Layout.js
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';

function HomeLayout() {
    return (
        <div style={{
            background: "linear-gradient(to top, #5068E1, #7A5DC7)",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        }}>
            <Outlet />
            <footer style={{ paddingTop: "1rem" }}>
                <Container>
                    <p style={{ textAlign: "center" }}>
                        © {new Date().getFullYear()}{" "}
                        <a>Optica, Inc.</a>
                    </p>
                </Container>
            </footer>
        </div>
    );
}

export default HomeLayout;