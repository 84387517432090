import "@aws-amplify/ui-react/styles.css";
import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Authenticator } from '@aws-amplify/ui-react';

import DataUploader from "../pages/data_uploader/DataUploader.js";
import DataViewer from "../pages/data_viewer/DataViewer.js";
import Profile from "../pages/profile/Profile.js";
import CreateTeam from '../pages/team_manager/CreateTeam.js';
import TeamManager from "../pages/team_manager/TeamManager.js";
import Home from "../pages/home/Home.js";
import Login from "../pages/login/Login.js";
import SignUp from "../pages/login/SignUp.js"
import HomeLayout from '../pages/layouts/HomeLayout.js';
import DashboardLayout from '../pages/layouts/DashboardLayout.js';
import RequireSignUp from '../pages/login/RequireSignUp.js';

import UserContext from '../pages/contexts/UserContext.js';
import TeamContext from '../pages/contexts/TeamContext.js';
import DataViewerContext from '../pages/contexts/DataViewerContext.js';
import User from '../models/user.js'

import './App.css';

import { faUsers, faUser, faChartBar, faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

const navLinks = [
  { to: "/dashboard/data-uploader", label: "Data Uploader", icon: faCloudUploadAlt, },
  { to: "/dashboard/data-viewer", label: "Data Viewer", icon: faChartBar, },
  { to: "/dashboard/team-manager", label: "Team Manager", icon: faUsers },
  { to: "/dashboard/profile", label: "Profile", icon: faUser }
];


function App() {
  const [user, setUser] = useState(new User());
  const [team, setTeam] = useState(null);
  const [dataViewerState, setDataViewerState] = useState({ data: [], lastEvaluatedKey: null, filters: { patientId: "", patientDob: null, vault: false } });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <TeamContext.Provider value={{ team, setTeam }}>
        <DataViewerContext.Provider value={{ dataViewerState, setDataViewerState }}>
          <Authenticator.Provider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<HomeLayout />}>
                  <Route index element={<Home />} />
                  <Route path="login" element={<Login />} />
                  <Route path="signup" element={<RequireSignUp isToSignup={true}><SignUp /></RequireSignUp>} />
                </Route>
                <Route path="/dashboard/*" element={<RequireSignUp><DashboardLayout /></RequireSignUp>}>
                  <Route index element={<Navigate to="data-uploader" />} />
                  <Route path="data-uploader" element={<DataUploader />} />
                  <Route path="data-editor" element={<DataUploader />} />
                  <Route path="data-viewer" element={<DataViewer />} />
                  <Route path="team-manager" element={<TeamManager />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="create-team" element={<CreateTeam/>} />
                </Route>
              </Routes>
            </BrowserRouter>
          </Authenticator.Provider>
        </DataViewerContext.Provider>
      </TeamContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
export { navLinks };
